import React from "react";
import "./scss/AnimalIcons.scss";
import "../../assets/styles/global.css";
import { Typography } from "antd";

const { Paragraph } = Typography;

interface AnimalIcon {
  altText: string;
  src: string;
  title: string;
  description: string;
  width?: number;
  height?: number;
}

const animalIcons: AnimalIcon[] = [
  {
    altText: "duck",
    src: "https://img.icons8.com/ios-filled/50/duck.png",
    title: "Birds",
    description:
      "Colorful creatures admired for their singing, vibrant plumage, and social behaviors",
    width: 50,
    height: 50,
  },
  {
    altText: "cat-head",
    src: "https://img.icons8.com/ios-filled/50/cat-head.png",
    title: "Cats",
    description:
      "Independent and curious animals with a penchant for lounging and exploring",
    width: 50,
    height: 50,
  },
  {
    altText: "dog--v2",
    src: "https://img.icons8.com/ios-filled/50/dog--v2.png",
    title: "Dogs",
    description:
      "Loyal companions known for their playful nature and strong bond with humans",
    width: 80,
    height: 80,
  },
  {
    altText: "guinea-pig",
    src: "https://img.icons8.com/ios-filled/50/guinea-pig.png",
    title: "Other",
    description:
      "Diverse group including fish, reptiles, and small mammals, each with unique care needs and characteristics",
    width: 50,
    height: 50,
  },
];

const AnimalIcons = () => {
  return (
    <section className="feature-v20 cd-padding-y-xl">
      <div className="cd-container cd-max-width-adaptive-lg">
        <ul className="feature-v20__list animal-list-wrapper">
          {animalIcons.map((icon) => (
            <li key={icon.altText} className="feature-v20__item">
              <figure className="feature-v20__icon-wrapper">
                <img
                  className="feature-v20__icon cd-icon"
                  width={icon.width}
                  height={icon.height}
                  src={icon.src}
                  alt={icon.altText}
                />
              </figure>
              <div className="cd-text-center">
                <h4 className="cd-margin-bottom-2xs">{icon.title}</h4>
                <Paragraph className="feature-v20__paragraph">
                  {icon.description}
                </Paragraph>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default AnimalIcons;
