import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import './RelatedProducts.css';
import "../../assets/styles/global.css";
import Spacer from '../Spacer/Spacer';

const { Title } = Typography;
const { Meta } = Card;

// Define the structure of a Product object
interface Product {
  _id: string;
  title: string;
  description: string;
  imageUrl: string;
}

// Props for RelatedProducts component
interface RelatedProductsProps {
  category: string;
}

const RelatedProducts: React.FC<RelatedProductsProps> = ({ category }) => {
  const [relatedProducts, setRelatedProducts] = useState<Product[]>([]);

  // Fetch related products when the category changes
  useEffect(() => {
    const fetchRelatedProducts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URI}/api/products/category/${category}?limit=4`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch related products');
        }
        const data: Product[] = await response.json();
        setRelatedProducts(data);
      } catch (error) {
        console.error('Failed to fetch related products:', error);
      }
    };

    if (category) {
      fetchRelatedProducts();
    }
  }, [category]);

  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
      <div className="related-products">
        <Title level={3}>Related Products</Title>
        <Spacer />
        <Row gutter={[16, 16]}>
          {relatedProducts.map((product) => (
            <Col key={product._id} xs={24} sm={12} md={12} lg={6}>
              <Link to={`/product/${product._id}`}>
                <Card
                  hoverable
                  cover={<img alt={product.title} src={product.imageUrl} />}
                >
                  <Meta title={product.title} description={product.description} />
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
};

export default RelatedProducts;
