import React, { useEffect, useState } from 'react';
import './ArticlePage.css';
import '../../assets/styles/global.css';
import { Typography } from 'antd';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const { Text, Title, Paragraph } = Typography;

interface Article {
  mainTitle: string;
  subTitle: string;
  authorImg: string;
  authorName: string;
  publishingDate: string;
  image1Link: string;
  text1: string;
  text2: string;
  subTitle2: string;
  text3: string;
  quote: string;
  subTitle3: string;
  text4: string;
  image2Link: string;
  image2Caption: string;
  text5: string;
  image3Link: string;
  image3Caption: string;
  text6: string;
  image4Link: string;
  image4Caption: string;
  text7: string;
  text8: string;
  videoLink: string;
  videoDescription: string;
}

const ArticlePage: React.FC = () => {
  const { articleId } = useParams<{ articleId: string }>();
  const [article, setArticle] = useState<Article | null>(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/api/news/${articleId}`);
        setArticle(response.data);
      } catch (error) {
        console.error('There was an error fetching the article!', error);
      }
    };

    fetchArticle();
  }, [articleId]);

  if (!article) {
    return <div>Loading...</div>;
  }

  return (
    <article className="ad5-padding-y-lg">
      <header className="ad5-container ad5-max-width-xs ad5-margin-bottom-lg">
        <div className="ad5-text-component ad5-text-center ad5-line-height-lg ad5-text-gap-md ad5-margin-bottom-md">
          <Title className='mainTitle' level={2}>{article.mainTitle}</Title>
          <Paragraph  className="ad5-color-contrast-medium ad5-text-md subTitle">
            {article.subTitle}
          </Paragraph>
        </div>
        <div className="ad5-flex ad5-justify-center">
          <div className="author author--meta">
            <a href="#0" className="author__img-wrapper">
              <img className='authorImg' src={article.authorImg} alt="Author" />
            </a>
            <div className="author__content ad5-text-component ad5-text-gap-2xs">
              <h4 className="ad5-text-base authorName"><a href="#0" rel="author">{article.authorName}</a></h4>
              <Text strong className="ad5-text-sm ad5-color-contrast-medium">
                <time className='publishingDate'>{new Date(article.publishingDate).toLocaleDateString()}</time> &mdash; 5 min read
              </Text>
            </div>
          </div>
        </div>
      </header>
      <figure className="ad5-container ad5-max-width-lg ad5-margin-bottom-lg">
        <img className="ad5-block ad5-width-100% ad5-radius-lg image1Link" src={article.image1Link} alt="Imagedescription" />
      </figure>
      <div className="ad5-container ad5-max-width-adaptive-sm">
        <div className="ad5-text-component ad5-line-height-lg ad5-text-gap-md">
          <Paragraph className='text1'>{article.text1}</Paragraph>
          <Paragraph className='text2'>{article.text2}</Paragraph>
          <Title className='subTitle2' level={2}>{article.subTitle2}</Title>
          <Paragraph className='text3'>{article.text3}</Paragraph>
          <blockquote><Paragraph className='quote'>{article.quote}</Paragraph></blockquote>
          <hr />
          <div className="ad5-text-component__block ad5-text-component__block--outset">
            <Title level={3} className="ad5-text-center subTitle3">{article.subTitle3}</Title>
          </div>
          <Paragraph className='text4'>{article.text4}</Paragraph>
          <figure className="ad5-text-component__block">
            <img className='image2Link' src={article.image2Link} alt="Imagedescription" />
            <figcaption className='image2Caption'>{article.image2Caption}</figcaption>
          </figure>
          <Paragraph className='text5'>{article.text5}</Paragraph>
          <figure className="ad5-text-component__block ad5-text-component__block--left">
            <img className='image3Link' src={article.image3Link} alt="Imagedescription" />
            <figcaption><Text strong className='image3Caption'>{article.image3Caption}</Text></figcaption>
          </figure>
          <Paragraph className='text6'>{article.text6}</Paragraph>
          <figure className="ad5-text-component__block ad5-text-component__block--outset ad5-text-component__block--right">
            <img className='image4Link' src={article.image4Link} alt="Imagedescription" />
            <figcaption><Text strong>{article.image4Caption}</Text></figcaption>
          </figure>
          <Paragraph className='text7'>{article.text7}</Paragraph>
          <Paragraph className='text8'>{article.text8}</Paragraph>
          <div className="ad5-text-component__block ad5-text-component__block--outset">
            <figure className="ad5-media-wrapper-16:9">
              <iframe className='videoLink' width="560" height="315" src={article.videoLink} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title='video'></iframe>
            </figure>
          </div>
          <Paragraph className='videoDescription'>{article.videoDescription}</Paragraph>
        </div>
      </div>
    </article>
  );
};

export default ArticlePage;
