import { useState, useEffect } from "react";
import {
  Card,
  List,
  Button,
  Modal,
  message,
  Typography,
} from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { AddOrEditPetModal, ModalType } from "../AddOrEditPetModal";

const { Text } = Typography;

const UserPets = () => {
  const { user } = useAuth0();
  const [pets, setPets] = useState([]);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [addOrEditModalType, setAddOrEditModalType] = useState(null);
  const [selectedPet, setSelectedPet] = useState(null);

  useEffect(() => {
    if (user) {
      fetchPets(user.sub);
    }
  }, [user]);

  const fetchPets = async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/pets/user/${userId}`
      );
      setPets(response.data);
    } catch (error) {
      console.error("Error fetching pets:", error);
    }
  };

  const showDeleteConfirm = (pet) => {
    setSelectedPet(pet);
    setIsDeleteModalVisible(true);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URI}/api/pets/remove/${selectedPet._id}`
      );
      message.success("Pet deleted successfully");
      setPets(pets.filter((pet) => pet._id !== selectedPet._id));
      setIsDeleteModalVisible(false);
    } catch (error) {
      message.error("Error deleting pet");
      console.error("Error deleting pet:", error);
    }
  };

  const showEditForm = (pet) => {
    setSelectedPet(pet);
    setAddOrEditModalType(ModalType.EDIT);
  };

  const showAddForm = () => {
    setAddOrEditModalType(ModalType.ADD);
  };

  const handleAddNewPet = (newPet) => {
    setPets((prevPets) => [...prevPets, newPet]);
  };

  const handleOnEditPet = (values) => {
    setPets(
      pets.map((pet) =>
        pet._id === selectedPet._id ? { ...pet, ...values } : pet
      )
    );
  }

  const handleCancel = () => {
    setIsDeleteModalVisible(false);
    setAddOrEditModalType(null);
  };

  return (
    <Card title="Pets">
      <List
        itemLayout="horizontal"
        dataSource={pets}
        renderItem={(pet) => (
          <List.Item
            actions={[
              <Button
                type="text"
                icon={<EditOutlined />}
                onClick={() => showEditForm(pet)}
              />,
              <Button
                type="text"
                icon={<DeleteOutlined />}
                onClick={() => showDeleteConfirm(pet)}
              />,
            ]}
          >
            <List.Item.Meta
              avatar={
                <img
                  alt={pet.name}
                  src={pet.photoUrl}
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "cover",
                    borderRadius: "50%",
                    border: "2px solid #d9d9d9", // Light border color
                  }}
                />
              }
              title={pet.name}
              description={
                <div>
                  <Text strong>Breed:</Text> <Text>{pet.breed}</Text>
                  <br />
                  <Text strong>Species:</Text> <Text>{pet.species}</Text>
                  <br />
                  <Text strong>Gender:</Text> <Text>{pet.gender}</Text>
                  <br />
                  <Text strong>Weight:</Text> <Text>{pet.weight} kg</Text>
                </div>
              }
            />
          </List.Item>
        )}
      />
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={showAddForm}
        style={{ marginTop: "16px" }}
      >
        Add Pet
      </Button>

      <Modal
        title="Delete Pet"
        open={isDeleteModalVisible}
        onOk={handleDelete}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to delete this pet?</p>
      </Modal>

      <AddOrEditPetModal
        modalType={addOrEditModalType}
        addNewPet={handleAddNewPet}
        onEdit={handleOnEditPet}
        selectedPet={selectedPet}
        isVisible={addOrEditModalType !== null}
        closeModal={() => setAddOrEditModalType(null)}
      />
    </Card>
  );
};

export default UserPets;
