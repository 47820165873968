import React, { createContext, useContext, useEffect, useState, ReactNode, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { message } from 'antd';

interface Booking {
  id: string;
  serviceType: string;
  petName: string;
  date: string;
  time: string;
  location: string;
  provider: string;
  price: number;
  paymentStatus: string;
  payoutStatus: string;
}

interface Pet {
  _id: string;
  name: string;
  birthday: string;
  species: string;
  gender: string;
  breed: string;
  weight: number;
  color: string;
  photoUrl: string;
}

interface Provider {
  providerId: string;
  name: string;
  email: string;
  startingPrice?: number;
  type: 'Partner' | 'Caregiver';
}

interface BookingDetails {
  [key: string]: any;
  dayOfWeek?: string;
}

interface BookingContextType {
  bookings: Booking[];
  bookingDetails: BookingDetails;
  selectedPet: Pet | null;
  selectedProvider: Provider | null;
  selectedService: string | null;
  userId: string | null;
  fetchBookings: () => Promise<void>;
  addBooking: (newBooking: Booking) => Promise<void>;
  removeBooking: (bookingId: string) => Promise<void>;
  updateBookingDetails: (field: string, value: any) => void;
  updateSelectedPet: (pet: Pet) => void;
  updateSelectedProvider: (provider: Provider) => void;
  updateSelectedService: (service: string) => void;
  clearBookingDetails: () => void;
  getTotalBookingPrice: () => number;
  addToCart: () => Promise<void>;
  isAuthenticated: boolean;
  user: any;
  isLoading: boolean;
}

export const BookingContext = createContext<BookingContextType | null>(null);

export const useBookingContext = () => {
  const context = useContext(BookingContext);
  if (!context) {
    throw new Error('useBookingContext must be used within a BookingContextProvider');
  }
  return context;
};

interface BookingContextProviderProps {
  children: ReactNode;
}

const BookingContextProvider: React.FC<BookingContextProviderProps> = (props) => {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [bookingDetails, setBookingDetails] = useState<BookingDetails>({});
  const [selectedPet, setSelectedPet] = useState<Pet | null>(null);
  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(null);
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const { isAuthenticated, isLoading, user } = useAuth0();

  const fetchBookings = useCallback(async () => {
    if (!userId) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/bookings/${userId}`);
      if (!response.ok) throw new Error('Failed to fetch bookings');
      const data = await response.json();
      setBookings(data);
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  }, [userId]);

  useEffect(() => {
    if (isAuthenticated && user && user.sub) {
      setUserId(user.sub);
    }
  }, [isAuthenticated, user]);

  const addBooking = async (newBooking: Booking) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/bookings`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newBooking),
      });
      if (!response.ok) throw new Error('Failed to add booking');
      const data = await response.json();
      setBookings((prev) => [...prev, data]);
    } catch (error) {
      console.error('Error adding booking:', error);
    }
  };

  const removeBooking = async (bookingId: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/bookings/${bookingId}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.ok) throw new Error('Failed to remove booking');
      setBookings((prev) => prev.filter((booking) => booking.id !== bookingId));
    } catch (error) {
      console.error('Error removing booking:', error);
    }
  };

  const updateBookingDetails = (field: string, value: any) => {
    setBookingDetails((prevDetails) => {
      let updatedDetails = { ...prevDetails, [field]: value };
      if (field === 'date' && value) {
        const dayOfWeek = new Date(value).toLocaleDateString('en-US', { weekday: 'long' });
        updatedDetails = { ...updatedDetails, dayOfWeek };
      }
      return updatedDetails;
    });
  };

  const updateSelectedPet = (pet: Pet) => setSelectedPet(pet);
  const updateSelectedService = (service: string) => setSelectedService(service);
  const updateSelectedProvider = (provider: Provider) => setSelectedProvider(provider);

  const getTotalBookingPrice = () => bookings.reduce((total, booking) => total + booking.price, 0);

  const clearBookingDetails = () => {
    setBookingDetails({});
    setSelectedPet(null);
    setSelectedProvider(null);
    setSelectedService(null);
  };

  const addToCart = async () => {
    if (!userId || !selectedService || !selectedProvider || !bookingDetails.date || !selectedPet) {
      message.error("Incomplete booking details");
      return;
    }
  
    // Create the booking payload with all required fields
    const bookingPayload = {
      userId,
      serviceType: selectedService,
      providerId: selectedProvider.providerId,  // Ensure this is coming from `selectedProvider`
      providerName: selectedProvider.name,      // Ensure this is coming from `selectedProvider`
      price: bookingDetails.price || 0,
      date: bookingDetails.date,
      time: bookingDetails.time,
      location: bookingDetails.location || '',
      pet: selectedPet.name,
      addons: bookingDetails.addons || [],
    };
  
    console.log("Booking Payload:", bookingPayload); // Debugging the payload
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/cart/addToCart`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bookingPayload),
      });
  
      if (!response.ok) throw new Error('Failed to add booking to cart');
      message.success('Booking added to cart successfully!');
    } catch (error) {
      message.error('Error adding booking to cart');
      console.error('Error adding booking to cart:', error);
    }
  };
  
  

  const contextValue: BookingContextType = {
    bookings,
    bookingDetails,
    selectedPet,
    selectedProvider,
    selectedService,
    userId,
    fetchBookings,
    addBooking,
    removeBooking,
    updateBookingDetails,
    updateSelectedPet,
    updateSelectedProvider,
    updateSelectedService,
    clearBookingDetails,
    getTotalBookingPrice,
    addToCart,
    isAuthenticated,
    user,
    isLoading,
  };

  return <BookingContext.Provider value={contextValue}>{props.children}</BookingContext.Provider>;
};

export default BookingContextProvider;
