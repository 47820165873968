import React from "react";
import { Typography } from "antd";
import { ServiceCategoryCard } from "../ServiceCategoryCard";
import "../../assets/styles/global.css";
import "./scss/ServiceCategories.scss";

const { Title } = Typography;

interface Service {
  title: string;
  description: string;
  imageUrl: string;
  altText: string;
  bookingLink: string;
}

export const SERVICES: Service[] = [
  {
    title: "Dog Walking",
    description:
      "Professional and reliable exercise and bathroom breaks for dogs, promoting physical health and mental stimulation.",
    imageUrl:
      "https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587570/15_nzkj4z.png",
    altText: "Dog Walking",
    bookingLink: "/dog-walking",
  },
  {
    title: "Pet Sitting",
    description:
      "In-home care for pets while owners are away, ensuring their comfort, safety, and routine are maintained.",
    imageUrl:
      "https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587570/16_nja4tx.png",
    altText: "Pet Sitting",
    bookingLink: "/pet-sitting",
  },
  {
    title: "Grooming",
    description:
      "Hygienic care for pets, including bathing, brushing, and trimming, enhancing their appearance and overall health.",
    imageUrl:
      "https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587569/10_mu2fhg.png",
    altText: "Grooming",
    bookingLink: "/groom-pet",
  },
  {
    title: "Pet Photography",
    description:
      "Specializes in capturing high-quality photographs of pets, either in studio settings or outdoor environments, producing memorable images that celebrate the unique personalities of animals.",
    imageUrl:
      "https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587569/11_qndspg.png",
    altText: "Pet Photography",
    bookingLink: "/pet-photo",
  },
  {
    title: "Training",
    description:
      "Behavioral and obedience training sessions tailored to pets' needs, fostering good manners and strengthening the bond between pets and owners.",
    imageUrl:
      "https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587569/14_smg0e9.png",
    altText: "Training",
    bookingLink: "/train-pet",
  },
  // {
  //   title: "Pet Transportation",
  //   description:
  //     "Safe and stress-free transport services for pets to vet appointments, grooming, or relocation, ensuring comfort and security during travel.",
  //   imageUrl:
  //     "https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587568/5_yt1zif.png",
  //   altText: "Pet Transportation",
  //   bookingLink: "/transport-pet",
  // },
  // {
  //   title: "Veterinary Services",
  //   description:
  //     "Comprehensive medical care provided by licensed veterinarians, including check-ups, vaccinations, and treatment for illnesses, promoting pets' health and well-being.",
  //   imageUrl:
  //     "https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587567/1_rt1rvj.png",
  //   altText: "Veterinary Services",
  //   bookingLink: "/vet-services",
  // },
  // {
  //   title: "Pet Boarding",
  //   description:
  //     "A service where pets are cared for overnight or for extended periods in a facility equipped to accommodate their needs, providing a safe and comfortable environment in the absence of their owners.",
  //   imageUrl:
  //     "https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587567/2_gpusbz.png",
  //   altText: "Pet Boarding",
  //   bookingLink: "/pet-boarding",
  // },
  // {
  //   title: "Pet Insurance",
  //   description:
  //     "Get your pet the insurance coverage needed from our trusted pet insurance providers, compare quotes and coverage ranges.",
  //   imageUrl:
  //     "https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587569/8_o0o2ae.png",
  //   altText: "Pet Insurance",
  //   bookingLink: "/pet-insurance",
  // },
  // {
  //   title: "Pet Adoption Services",
  //   description:
  //     "Facilitates the adoption of pets from shelters or rescue organizations to loving homes, matching pets with prospective owners and providing support throughout the adoption process.",
  //   imageUrl:
  //     "https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587569/12_rnsjyk.png",
  //   altText: "Pet Adoption Services",
  //   bookingLink: "/adopt-a-pet",
  // },
  // {
  //   title: "Pet Funeral Services",
  //   description:
  //     "Offers dignified and compassionate end-of-life arrangements for pets, including cremation, burial, memorial services, and grief support for pet owners coping with the loss of their beloved companions.",
  //   imageUrl:
  //     "https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587568/7_n8jrkt.png",
  //   altText: "Pet Funeral Services",
  //   bookingLink: "/funeral-services",
  // },
];

interface ServiceCategoriesProps {
  title?: string;
  limit?: number; // Optional prop to specify the number of services to display
}

export const ServiceCategories: React.FC<ServiceCategoriesProps> = ({ title, limit }) => {
  const displayedServices = limit ? SERVICES.slice(0, limit) : SERVICES;

  return (
    <section className="top-services">
      {title && (
        <h2 className="top-services__title">
          <Title level={2}>{title}</Title>
        </h2>
      )}

      <div className="top-services__grid">
        {displayedServices.map((service) => (
          <ServiceCategoryCard
            key={service.title}
            title={service.title}
            description={service.description}
            imageUrl={service.imageUrl}
            altText={service.altText}
            bookingLink={service.bookingLink}
          />
        ))}
      </div>
    </section>
  );
};

export default ServiceCategories;
