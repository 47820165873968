import React, { useEffect } from 'react';
import './CareersHero.css';
import { initSnowFall } from './snowFall';
import { Button } from 'antd';
import Spacer from '../Spacer/Spacer';
import { Link } from 'react-router-dom';

const CareersHero: React.FC = () => {
  useEffect(() => {
    initSnowFall();
  }, []);

  return (
    <section className="Hero">
      <div className="Hero__content">
        <h2 className="Hero__title" style={{ color: 'white' }}>Build a bright and fulfilling future</h2>
        <p className="Hero__subtitle">Check out available career opportunities at Paw Pros</p>
        <Spacer />
        <div className="Hero__action" role="button" tabIndex={0}>
          <Button type="text" className="Hero__actionText">
            <Link to="/">See More</Link>
          </Button>
        </div>
      </div>
      <div className="Hero__image" id="Hero__image"></div>
      <div className="Hero__mask"></div>
      <canvas id="snowfall" className="Hero__snowfall"></canvas>
    </section>
  );
};

export default CareersHero;
