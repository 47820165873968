import React, { useEffect, useState } from "react";
import "../../assets/styles/global.css";
import "./ProductDisplay.css";
import { HomeOutlined, ShopOutlined } from "@ant-design/icons";
import { Breadcrumb, Typography, Button, InputNumber, Rate, notification, Modal } from "antd";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useShopContext } from "../../context/ShopContext"; // Import the context

const { Text, Paragraph, Title } = Typography;

interface Product {
  _id: string;
  title: string;
  description: string;
  price: number;
  rating: number;
  imageUrl: string;
  category: string;
  inventory: Array<{ variation: string; quantity: number }>;
}

interface ProductDisplayProps {
  setCategory: (category: string) => void;
}

const ProductDisplay: React.FC<ProductDisplayProps> = ({ setCategory }) => {
  const { productId } = useParams<{ productId: string }>();
  const { addToCart, userId } = useShopContext(); // Destructure addToCart and userId from the context
  const [product, setProduct] = useState<Product | null>(null);
  const [selectedVariation, setSelectedVariation] = useState<{ variation: string; quantity: number } | null>(null);
  const [quantity, setQuantity] = useState<number>(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/products/${productId}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setProduct(data);
        setSelectedVariation(data.inventory[0]); // Set default selected variation
        setCategory(data.category); // Pass the category to the parent component
      } catch (error) {
        console.error("Failed to fetch product:", error);
        notification.error({
          message: 'Error',
          description: 'Failed to fetch product details.',
        });
      }
    };

    fetchProduct();
  }, [productId, setCategory]);

  const handleAddToCart = async () => {
    if (!userId) {
      setIsModalVisible(true);
      return;
    }

    if (selectedVariation && selectedVariation.quantity >= quantity) {
      try {
        await addToCart(userId, product!._id, selectedVariation.variation, Number(quantity));
        notification.success({
          message: 'Success',
          description: 'Product added to cart successfully.',
        });
      } catch (error) {
        console.error("Failed to add product to cart:", error);
        notification.error({
          message: 'Error',
          description: 'Failed to add product to cart.',
        });
      }
    } else {
      notification.error({
        message: 'Error',
        description: 'Selected quantity exceeds available stock.',
      });
    }
  };

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
      <section className="product">
        <div className="pu0-margin-bottom-sm">
          <nav className="breadcrumbs pu0-text-sm" aria-label="Breadcrumbs">
            <Breadcrumb
              items={[
                {
                  href: "/",
                  title: <HomeOutlined />,
                },
                {
                  href: "/shop",
                  title: (
                    <>
                      <ShopOutlined />
                      <span>Shop</span>
                    </>
                  ),
                },
                {
                  title: product.title,
                },
              ]}
            />
          </nav>
        </div>

        <div className="pu0-grid pu0-gap-md">
          <div className="pu0-col-6@md pu0-col-7@lg">
            <figure className="image-zoom js-image-zoom">
              <img
                className="image-zoom__preview js-image-zoom__preview"
                src={product.imageUrl}
                alt={product.title}
              />
              <figcaption className="pu0-sr-only">{product.title}</figcaption>
            </figure>
          </div>

          <div className="pu0-col-6@md pu0-col-5@lg">
            <div className="pu0-margin-bottom-xs">
              <Title level={2}>{product.title}</Title>
            </div>

            <div className="pu0-flex">
              <div className="rating rating--read-only js-rating js-rating--read-only pu0-text-xs">
                <Rate defaultValue={product.rating} />
              </div>

              <div className="pu0-margin-left-2xs">
                <Link to={`/reviews/${product._id}`} className="pu0-text-sm pu0-color-inherit">
                  <Paragraph>Reviews</Paragraph>
                </Link>
              </div>
            </div>

            <div className="pu0-text-component pu0-text-gap-md pu0-margin-y-md">
              <Paragraph>{product.description}</Paragraph>
              <Text strong className="pu0-text-md">
                <Title level={3} className="pu0-text-decoration-none">${product.price}</Title>
              </Text>
            </div>

            <div className="pu0-margin-bottom-md">
              <Paragraph strong>Select Variation</Paragraph>
              <div className="btns pu0-inline-flex pu0-gap-xs btns--radio">
                {product.inventory.map((variation, index) => (
                  <div key={index}>
                    <Button
                      type={selectedVariation === variation ? "primary" : "default"}
                      onClick={() => setSelectedVariation(variation)}
                    >
                      {variation.variation}
                    </Button>
                  </div>
                ))}
              </div>
            </div>

            <div className="pu0-flex pu0-gap-xs">
              <div className="number-input number-input--v1 js-number-input">
                <InputNumber
                  min={1}
                  max={selectedVariation ? selectedVariation.quantity : 1}
                  value={quantity}
                  onChange={(value) => setQuantity(value as number)}
                />
              </div>
              <Button type="primary" onClick={handleAddToCart}>
                Add to Cart
              </Button>
            </div>
          </div>
        </div>
      </section>

      <Modal
        title="Login Required"
        visible={isModalVisible}
        onOk={handleLoginRedirect}
        onCancel={handleCancel}
        okText="Login"
        cancelText="Cancel"
      >
        <p>You must be logged in to add items to the cart.</p>
      </Modal>
    </section>
  );
};

export default ProductDisplay;
