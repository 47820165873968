import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Pet } from "../../types";
import { AddOrEditPetModal, ModalType } from "../AddOrEditPetModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useBookingContext } from "../../context/BookingContext"; // Import the BookingContext
import "../../assets/styles/global.css";
import "./PetsGrid.css";
import dayjs from "dayjs";

const { Meta } = Card;
const { Text } = Typography;

interface PetsGridProps {
  service: string;
}

const PetsGrid: React.FC<PetsGridProps> = ({ service }) => {
  const { user, isAuthenticated } = useAuth0();
  const [pets, setPets] = useState<Pet[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPet, setSelectedPet] = useState<Pet | null>(null);
  const navigate = useNavigate();
  const { bookingDetails, updateSelectedPet, updateSelectedService } =
    useBookingContext(); // Access bookingDetails from the context

  useEffect(() => {
    if (isAuthenticated && user) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URI}/api/pets/user/${user.sub}`)
        .then((response) => {
          setPets(response.data);
        })
        .catch((error) => {
          console.error("There was an error fetching the pets!", error);
        });
    }
  }, [isAuthenticated, user]);

  const handleAddNewPet = () => {
    setIsModalVisible(true);
  };

  const handlePetClick = (pet: Pet) => {
    setSelectedPet(pet);
    updateSelectedPet(pet); // Store the selected pet in the booking context
    updateSelectedService(service); // Store the selected service in the booking context
  };

  const handleChooseProviderClick = () => {
    if (selectedPet) {
      navigate("/choose-provider", {
        state: {
          pet: selectedPet,
          service,
          bookingData: bookingDetails, // Use bookingDetails from context
        },
      });
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const onAddNewPet = (newPet: Pet) => {
    setPets((prevPets) => [...prevPets, newPet]);
  };

  const renderPets = () => {
    return pets.map((pet, index) => (
      <Col key={index} xs={24} sm={12} md={8} lg={6}>
        <Card
          hoverable
          cover={<img alt={pet.name} src={pet.photoUrl} />}
          onClick={() => handlePetClick(pet)}
          className={
            selectedPet && selectedPet._id === pet._id ? "selected-card" : ""
          }
        >
          <Meta
            title={pet.name}
            description={
              <div>
                <Text strong>Birthday:</Text>{" "}
                <Text>{dayjs(pet.birthday).format("MMMM D, YYYY")}</Text>
                <br />
                <Text strong>Species:</Text>{" "}
                <Text style={{ textTransform: "capitalize" }}>
                  {pet.species}
                </Text>
                <br />
                <Text strong>Gender:</Text>{" "}
                <Text style={{ textTransform: "capitalize" }}>
                  {pet.gender}
                </Text>
                <br />
                <Text strong>Breed:</Text>{" "}
                <Text style={{ textTransform: "capitalize" }}>{pet.breed}</Text>
                <br />
                <Text strong>Weight:</Text>{" "}
                <Text style={{ textTransform: "capitalize" }}>
                  {pet.weight} lbs
                </Text>
                <br />
                <Text strong>Color:</Text>{" "}
                <Text style={{ textTransform: "capitalize" }}>{pet.color}</Text>
              </div>
            }
          />
        </Card>
      </Col>
    ));
  };

  return (
    <>
      <section className="ta0-container ta0-max-width-adaptive-lg">
        <Row gutter={[16, 16]}>
          {renderPets()}
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card
              hoverable
              onClick={handleAddNewPet}
              style={{ textAlign: "center", padding: "20px" }}
            >
              <PlusOutlined style={{ fontSize: "24px", marginRight: "8px" }} />
              Add New Pet
            </Card>
          </Col>
        </Row>
      </section>
      <div className="choose-providers-button-container">
        <Button
          type="primary"
          className="choose-providers-button"
          onClick={handleChooseProviderClick}
          disabled={!selectedPet}
        >
          Choose Providers
        </Button>
      </div>
      <AddOrEditPetModal
        modalType={ModalType.ADD}
        addNewPet={onAddNewPet}
        isVisible={isModalVisible}
        closeModal={closeModal}
      />
    </>
  );
};

export default PetsGrid;
