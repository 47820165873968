import React from 'react';
import './ArticlePreview.css';
import "../../assets/styles/global.css";
import { Link } from 'react-router-dom';

// Define types for the props
interface ArticlePreviewProps {
  imageUrl: string;
  category: string;
  title: string;
  authorImage: string;
  authorName: string;
  publishDate: string;
}

const ArticlePreview: React.FC<ArticlePreviewProps> = ({ imageUrl, category, title, authorImage, authorName, publishDate }) => {
  return (
    <article className="story">
      <Link className="story__img ai4-radius-md" to={'/'}>
        <figure>
          <img src={imageUrl} alt="Imagedescription" />
        </figure>
      </Link>

      <div className="story__content">
        <div className="ai4-margin-bottom-xs">
          <Link className="story__category" to={'/'}>
            <svg className="ai4-icon ai4-margin-right-3xs" aria-hidden="true" viewBox="0 0 16 16">
              <g strokeWidth='1' stroke='currentColor' fill='none' strokeLinecap='round' strokeLinejoin='round'>
                <circle cx='8' cy='7' r='1.5'></circle>
                <path d='M12.121,14.263a7.5,7.5,0,1,0-8.242,0'></path>
                <path d='M12.377,11.32a5.5,5.5,0,1,0-8.754,0'></path>
                <path d='M6.605,10.5H9.4a1,1,0,0,1,1,1.1L10,15.5H6l-.39-3.9A1,1,0,0,1,6.605,10.5Z'></path>
              </g>
            </svg>
            <i>{category}</i>
          </Link>
        </div>

        <div className="ai4-text-component">
          <h2 className="story__title"><Link to={'/'}>{title}</Link></h2>
        </div>

        <div className="story__author ai4-margin-top-sm">
          <Link className="block" to={'/'}>
            <img src={authorImage} alt="Authorpicture" />
          </Link>

          <div className="ai4-line-height-xs">
            <address className="story__author-name"><Link to={'/'} rel="author">{authorName}</Link></address>
            <p className="story__meta"><time>{publishDate}</time> &mdash; 5 min read</p>
          </div>
        </div>
      </div>
    </article>
  );
};

export default ArticlePreview;
