import React from 'react';
import './HeroBAPP.css';
import { Typography } from 'antd';

const { Title } = Typography;

const HeroBAPP: React.FC = () => {
  return (
    <section className="video-bg va6-position-relative">
      <div className="video-bg__media" aria-hidden="true">
        <img src="../../assets/img/video-background-hero-img-1.jpg" alt="Video Preview" />
        <video
          autoPlay
          loop
          muted
          playsInline
          src="https://res.cloudinary.com/daq4ywu5y/video/upload/v1723589957/Brown_Abstract_Pet_Activity_Intro_Outro_Youtube_Video_1_bsrjqc.mp4"
        ></video>
      </div>

      <div className="va6-container va6-max-width-lg va6-position-relative va6-z-index-2">
        <div className="va6-flex va6-flex-center va6-padding-y-3xl">
          <div className="va6-text-component va6-text-center">
            <Title level={1}>Become A Paw Pro</Title>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroBAPP;
