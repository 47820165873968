import React from 'react';
import { Typography, Row, Col, Card } from 'antd';
import { useBookingContext } from '../../context/BookingContext';
import './ServicePageBookingDetails.css';

const { Title, Paragraph, Text } = Typography;

const ServicePageBookingDetails: React.FC = () => {
  const { bookingDetails, selectedPet, selectedProvider } = useBookingContext(); // Now including selectedPet and selectedProvider from the context

  // Destructure necessary details from bookingDetails
  const {
    date,
    time,
    additionalInfo,
  } = bookingDetails;

  // Destructure pet details from selectedPet
  const {
    name: petName,
    species: petType,
    breed: petBreed,
    weight: petWeight,
  } = selectedPet || {}; // Add fallback for null/undefined selectedPet

  // Destructure provider details from selectedProvider
  const {
    name: providerName,
    email: providerEmail,
    startingPrice,
    type: providerType,
  } = selectedProvider || {}; // Add fallback for null/undefined selectedProvider

  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
      <div className="booking-display">
        <Card className="booking-display-card">
          <Row gutter={[16, 16]}>
            {/* Pet Details */}
            <Col xs={24} md={8}>
              <div className="details-section">
                <Title level={5}>Pet Details</Title>
                <Paragraph><Text strong>Name:</Text> {petName || 'N/A'}</Paragraph>
                <Paragraph><Text strong>Type:</Text> {petType || 'N/A'}</Paragraph>
                <Paragraph><Text strong>Breed:</Text> {petBreed || 'N/A'}</Paragraph>
                <Paragraph><Text strong>Weight:</Text> {petWeight ? `${petWeight} kg` : 'N/A'}</Paragraph>
              </div>
            </Col>

            {/* Booking Details */}
            <Col xs={24} md={8}>
              <div className="details-section">
                <Title level={5}>Booking Details</Title>
                <Paragraph><Text strong>Date:</Text> {date ? new Date(date).toLocaleDateString() : 'N/A'}</Paragraph>
                <Paragraph><Text strong>Time:</Text> {time ? new Date(time).toLocaleTimeString() : 'N/A'}</Paragraph>
                <Paragraph><Text strong>More Info:</Text> {additionalInfo || 'N/A'}</Paragraph>
              </div>
            </Col>

            {/* Provider Details */}
            <Col xs={24} md={8}>
              <div className="details-section">
                <Title level={5}>Provider Details</Title>
                <Paragraph><Text strong>Name:</Text> {providerName || 'N/A'}</Paragraph>
                <Paragraph><Text strong>Email:</Text> {providerEmail || 'N/A'}</Paragraph>
                <Paragraph><Text strong>Starting Price:</Text> {startingPrice ? `$${startingPrice}` : 'N/A'}</Paragraph>
                <Paragraph><Text strong>Type:</Text> {providerType || 'N/A'}</Paragraph>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </section>
  );
};

export default ServicePageBookingDetails;
