import React from 'react';
import { Row, Col, Typography, Rate, Card } from 'antd';
import './ProviderPreviousTestimonials.css';

const { Title, Paragraph, Text } = Typography;

const testimonials = [
  {
    rating: 3.5,
    quote: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi atque doloremque beatae!',
    name: 'Emily Ewing',
    
    image: 'path_to_image_1',
  },
  {
    rating: 5,
    quote: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur, recusandae?',
    name: 'James Powell',
    
    image: 'path_to_image_2',
  },
  {
    rating: 4.5,
    quote: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit provident numquam velit officiis impedit.',
    name: 'Olivia Gribben',
    
    image: 'path_to_image_3',
  },
];

const ProviderPreviousTestimonials: React.FC = () => {
  return (
    <section>
      <div className="container">
        <div className="title-container">
          <Title level={3} className="text-center">
            Previous Reviews and Ratings 
          </Title>
        </div>

        <Row gutter={[16, 16]} justify="center">
          {testimonials.map((testimonial, index) => (
            <Col key={index} xs={24} sm={24} md={8}>
              <Card className="testimonial-card">
                <div className="testimonial-rating">
                  <Rate disabled allowHalf defaultValue={testimonial.rating} />
                </div>
                <blockquote className="testimonial-quote">
                  <Paragraph>
                    <mark>{testimonial.quote}</mark>
                  </Paragraph>
                </blockquote>

                <div className="testimonial-footer">
                  <div className="testimonial-img-wrapper">
                    <img
                      className="testimonial-img"
                      src={testimonial.image}
                      alt={testimonial.name}
                    />
                  </div>

                  <cite>
                    <Text strong>{testimonial.name}</Text>
                    
                    
                  </cite>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
};

export default ProviderPreviousTestimonials;
