import React from 'react';
import ArticlePreview from '../ArticlePreview/ArticlePreview';
import '../../assets/styles/global.css';
import './BlogArticles.css';
import { Pagination, Typography } from 'antd';

const { Title, Paragraph, Text } = Typography;

interface Article {
  imageUrl: string;
  category: string;
  title: string;
  description?: string;
  authorImage: string;
  authorName: string;
  publishDate: string;
}

const mainArticle: Article = {
  imageUrl: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1720256646/3_xsshpu.png',
  category: 'Podcast',
  title: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit',
  description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At iusto sit quasi aliquam quod laboriosam. Nobis, modi repellendus.',
  authorImage: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1721315617/IMG_3568_udi2dp.jpg',
  authorName: 'Anne Duret',
  publishDate: 'May 16',
};

const articles: Omit<Article, 'description'>[] = [
  {
    imageUrl: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1720256646/3_xsshpu.png',
    category: 'Video',
    title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
    authorImage: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1721315617/IMG_3568_udi2dp.jpg',
    authorName: 'Anne Duret',
    publishDate: 'May 12',
  },
  {
    imageUrl: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1720256646/3_xsshpu.png',
    category: 'Video',
    title: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quis hic veniam harum?',
    authorImage: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1721315617/IMG_3568_udi2dp.jpg',
    authorName: 'Anne Duret',
    publishDate: 'May 8',
  },
  {
    imageUrl: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1720256646/3_xsshpu.png',
    category: 'Podcast',
    title: 'Lorem ipsum dolor sit amet consectetur',
    authorImage: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1721315617/IMG_3568_udi2dp.jpg',
    authorName: 'Anne Duret',
    publishDate: 'May 5',
  },
];

const BlogArticles: React.FC = () => {
  return (
    <div className="ar0-position-relative ar0-z-index-1 ar0-padding-y-xl">
      <div className="ar0-container ar0-max-width-adaptive-lg">
        <div className="ar0-grid ar0-gap-y-lg ar0-gap-lg@md">
          <div className="story story--featured">
            <a className="story__img ar0-radius-md" href="#0">
              <figure className="ar0-media-wrapper-4:3">
                <img src={mainArticle.imageUrl} alt="Imagedescription" />
              </figure>
            </a>
            <div className="story__content">
              <div className="ar0-margin-bottom-xs">
                <a className="story__category" href="#0">
                  <svg className="ar0-icon ar0-margin-right-3xs" aria-hidden="true" viewBox="0 0 16 16">
                    <g strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <circle cx="8" cy="7" r="1.5"></circle>
                      <path d="M12.121,14.263a7.5,7.5,0,1,0-8.242,0"></path>
                      <path d="M12.377,11.32a5.5,5.5,0,1,0-8.754,0"></path>
                      <path d="M6.605,10.5H9.4a1,1,0,0,1,1,1.1L10,15.5H6l-.39-3.9A1,1,0,0,1,6.605,10.5Z"></path>
                    </g>
                  </svg>
                  <i>{mainArticle.category}</i>
                </a>
              </div>
              <div className="ar0-text-component">
                <Title level={3} className="story__title">
                  <a href="#0">{mainArticle.title}</a>
                </Title>
                <Paragraph>{mainArticle.description}</Paragraph>
              </div>
              <div className="story__author ar0-margin-top-sm">
                <a className="block" href="#0">
                  <img src={mainArticle.authorImage} alt="Authorpicture" />
                </a>
                <div className="ar0-line-height-xs">
                  <address className="story__author-name">
                    <a href="#0" rel="author">
                      {mainArticle.authorName}
                    </a>
                  </address>
                  <Text className="story__meta">
                    <time>{mainArticle.publishDate}</time> &mdash; 5 min read
                  </Text>
                </div>
              </div>
            </div>
          </div>
          {articles.map((article, index) => (
            <div key={index} className="ar0-col-4@md">
              <ArticlePreview
                imageUrl={article.imageUrl}
                category={article.category}
                title={article.title}
                authorImage={article.authorImage}
                authorName={article.authorName}
                publishDate={article.publishDate}
              />
            </div>
          ))}
        </div>
        <nav className="pagination ar0-margin-top-2xl" aria-label="Pagination">
          <ol className="pagination__list ar0-flex ar0-flex-wrap ar0-gap-3xs ar0-justify-center">
            <li>
              <Pagination defaultCurrent={6} total={500} />
            </li>
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default BlogArticles;
