import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import SecondHero from "../components/SecondHero/SecondHero";
import ServiceCategories from "../components/ServiceCategories/ServiceCategories";
import DialogflowMessenger from "../components/DialogFlowMessenger/DialogFlowMessenger";

const ServicePage: React.FC = () => {
  return (
    <div>
      <Header />
      <SecondHero />
      <ServiceCategories />
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default ServicePage;
