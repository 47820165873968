import React from 'react';
import Header from '../components/Header/Header'; // Adjust the import paths based on your file structure
import Footer from '../components/Footer/Footer';
import ServicePageBookingDetails from '../components/ServicePageBookingDetails/ServicePageBookingDetails';
import ProviderPricingDisplay from '../components/ProviderPricingDisplay/ProviderPricingDisplay';
import ProviderPreviousTestimonials from '../components/ProviderPreviousTestimonials/ProviderPreviousTestimonials';
const ProviderServiceDetailPage: React.FC = () => {
  return (
    <>
      <Header />
      <ProviderPricingDisplay />
      <ServicePageBookingDetails />
      
      <ProviderPreviousTestimonials />
      <Footer />
    </>
  );
};

export default ProviderServiceDetailPage;
