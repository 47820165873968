import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header/Header';
import CertifiedPawPros from '../components/CertifiedPawPros/CertifiedPawPros';
import Footer from '../components/Footer/Footer';
import Spacer from '../components/Spacer/Spacer';
import PartnerDisplay from '../components/PartnersDisplay/PartnersDisplay';
import CaregiverDisplay from '../components/CaregiverDisplay/CaregiverDisplay';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';
import CommunityCaregivers from '../components/CommunityCaregivers/CommunityCaregivers';
import { useBookingContext } from '../context/BookingContext';

// Define the types for the provider, which could be either a Caregiver or Partner
interface Caregiver {
  _id: string;
  providerId: string;
  firstName: string;
  lastName: string;
  email: string;
  experienceLevel: string;
  startingPrice?: number;
}

interface Partner {
  _id: string;
  companyName: string;
  contactPerson: string;
  email: string;
  location: string;
  startingPrice?: number;
}

// Define the transformed provider type to include additional fields
interface TransformedProvider {
  providerId: string;
  name: string;
  email: string;
  startingPrice?: number;
  type: 'Partner' | 'Caregiver';
}

// Union type for providers that can be either Caregiver or Partner
type Provider = Caregiver | Partner;

const ProviderChoicePage: React.FC = () => {
  const location = useLocation();
  const { service, bookingData } = location.state || {};
  const { updateBookingDetails, updateSelectedProvider } = useBookingContext();
  const navigate = useNavigate();

  useEffect(() => {
    updateBookingDetails('providerDetails', bookingData); // Update provider details
  }, [bookingData, updateBookingDetails]);

  // Map Caregiver/Partner to TransformedProvider type
  const handleProviderSelect = (provider: Provider) => {
    const transformedProvider: TransformedProvider = {
      providerId: 'providerId' in provider ? provider.providerId : provider._id,
      name: 'companyName' in provider ? provider.companyName : `${provider.firstName} ${provider.lastName}`,
      email: provider.email,
      startingPrice: provider.startingPrice,
      type: 'companyName' in provider ? 'Partner' : 'Caregiver',
    };

    updateSelectedProvider(transformedProvider); // Update the selected provider in the context
    handleProceedToBooking(transformedProvider);
  };

  const handleProceedToBooking = (selectedProvider: TransformedProvider) => {
    if (selectedProvider) {
      navigate('/provider-service-details', {
        state: { provider: selectedProvider },
      });
    }
  };

  return (
    <div>
      <Header />
      <Spacer />
      <CertifiedPawPros />
      <Spacer />
      <PartnerDisplay selectedService={service} onProviderSelect={handleProviderSelect} />
      <Spacer />
      <CommunityCaregivers />
      <Spacer />
      <CaregiverDisplay selectedService={service} onProviderSelect={handleProviderSelect} />
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default ProviderChoicePage;
