import React from 'react';
import './CertifiedPawPros.css';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Text, Title } = Typography;

const CertifiedPawPros: React.FC = () => {
  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
      <Link to="/certified-pawpros" className="banner" aria-label="Shop now">
        <div className="banner__grid">
          <div aria-hidden="true">
            <figure
              className="banner__figure"
              style={{ backgroundImage: `url(${'https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116068/samples/animals/reindeer.jpg'})` }}
            ></figure>
          </div>

          <div className="banner__text">
            <Title level={2}>Certified Partners</Title>
            <Text className="banner__link-wrapper">
              <Text strong>
                Trusted local businesses and established companies vetted and certified by Paw Pros. These partners operate physical locations and have a proven track record in pet care services. They offer professional expertise and high-quality care for your beloved pets, ensuring peace of mind.
              </Text>
            </Text>
          </div>
        </div>
      </Link>
    </section>
  );
};

export default CertifiedPawPros;
