import React, { useContext, useEffect, useState } from "react";
import { ShopContext } from "../../context/ShopContext";
import { useAuth0 } from "@auth0/auth0-react";

import {
  Button,
  Typography,
  Image,
  Card,
  Row,
  Col,
  Divider,
  Input,
} from "antd";
import {
  CloseOutlined,
  ShoppingCartOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import "./CartItems.css";

const { Title, Text } = Typography;

const CartItems: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const { fetchCartItems, removeFromCart, increaseQuantity, decreaseQuantity, cartItems: products, bookings } =
    useContext(ShopContext);
  const [subtotal, setSubtotal] = useState(0);

  useEffect(() => {
    const calculateSubtotal = () => {
      let total = 0;

      products.forEach((product) => {
        total += product.cartItem.price * product.quantity;
      });
      bookings.forEach((booking) => {
        total += booking.price;
      });

      setSubtotal(total);
    };

    calculateSubtotal();
  }, [products, bookings]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchCartItems();
    }
  }, [fetchCartItems, isAuthenticated]);

  const handleProceedToShipping = () => {
    window.location.href = "/shipping";
  };

  // Increase quantity function
  const handleIncreaseQuantity = async (productId: string, variation: any) => {
    if (increaseQuantity) {
      await increaseQuantity(productId, variation);
    }
  };

  // Decrease quantity function
  const handleDecreaseQuantity = async (
    productId: string,
    variation: any,
    currentQuantity: number
  ) => {
    if (currentQuantity > 1 && decreaseQuantity) {
      await decreaseQuantity(productId, variation);
    }
  };

  const getFormatedPrice = (num: number) => {
    return `$${num.toFixed(2)}`;
  }

  return (
    <div className="cartitems">
      <Title>
        <ShoppingCartOutlined /> Shopping Cart
      </Title>
      <Divider />

      <Title level={4}>Products</Title>
      <Row gutter={[16, 16]}>
        {products.map((product) => (
          <Col xs={24} sm={12} md={8} key={product.cartItem._id}>
            <Card
              hoverable
              cover={
                <Image
                  src={product.cartItem.imageUrl}
                  alt={product.cartItem.title}
                />
              }
              actions={[
                <CloseOutlined
                  onClick={() => removeFromCart(product.cartItem._id, 'product')}
                />,
              ]}
            >
              <Card.Meta
                title={product.cartItem.title}
                description={`$${product.cartItem.price} x ${product.quantity}`}
              />
              <div className="quantity-adjuster">
                <Button
                  icon={<MinusOutlined />}
                  onClick={() =>
                    handleDecreaseQuantity(
                      product.cartItem._id,
                      product.variation,
                      product.quantity
                    )
                  }
                />
                <span className="quantity-number">{product.quantity}</span>
                <Button
                  icon={<PlusOutlined />}
                  onClick={() =>
                    handleIncreaseQuantity(
                      product.cartItem._id,
                      product.variation
                    )
                  }
                />
              </div>
              <Text strong>
                Total: {getFormatedPrice(product.cartItem.price * product.quantity)}
              </Text>
            </Card>
          </Col>
        ))}
      </Row>

      <Divider />

      <Title level={4}>Bookings</Title>
      <Row gutter={[16, 16]}>
        {bookings.map((booking) => (
          <Col xs={24} sm={12} md={8} key={booking._id}>
            <Card
              hoverable
              actions={[
                <CloseOutlined onClick={() => removeFromCart(booking._id, 'booking')} />,
              ]}
            >
              <Card.Meta
                title={booking.serviceType}
                description={`Provider: ${booking.providerName} - Pet: ${booking.pet}`}
              />
              <Text strong>Total: {getFormatedPrice(booking.price)}</Text>
            </Card>
          </Col>
        ))}
      </Row>

      <Divider />

      <div className="cartitems-total">
        <Title level={3}>Cart Totals</Title>
        <div className="cartitems-total-item">
          <Title level={5}>Subtotal</Title>
          <Text>${subtotal.toFixed(2)}</Text>
        </div>
        <div className="cartitems-total-item">
          <Title level={5}>Shipping Fee</Title>
          <Text>TBD</Text>
        </div>
        <div className="cartitems-total-item">
          <Title level={3}>Total</Title>
          <Text strong>${subtotal.toFixed(2)}</Text>
        </div>
        <Button type="primary" onClick={handleProceedToShipping}>
          PROCEED TO SHIPPING
        </Button>
      </div>

      <div className="cartitems-promocode">
        <Title level={4}>Promo Code</Title>
        <div className="cartitems-promobox">
          <Input placeholder="Enter your promo code" />
          <Button type="default">Apply</Button>
        </div>
      </div>
    </div>
  );
};

export default CartItems;
