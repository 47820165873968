import { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  Select,
  Upload,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import dayjs from "dayjs";
import { Pet } from "../../types";
import { useForm } from "antd/es/form/Form";

export enum ModalType {
  ADD = "ADD",
  EDIT = "EDIT",
}

interface BaseModalProps {
  modalType: ModalType; // Discriminant field
  // Other common fields for both 'add' and 'edit' modals
  isVisible: boolean;
  closeModal: () => void;
}

interface AddModalProps extends BaseModalProps {
  modalType: ModalType.ADD;
  addNewPet: (pet: Pet) => void;
}

interface EditModalProps extends BaseModalProps {
  modalType: ModalType.EDIT;
  selectedPet: Pet;
  onEdit: (updatedPet: Pet) => void;
}

type PetModalProps = AddModalProps | EditModalProps;

export const AddOrEditPetModal = ({ ...props }: PetModalProps) => {
  const { isVisible, closeModal, modalType } = props;

  const { user } = useAuth0();
  const [fileList, setFileList] = useState<any[]>([]);
  const [form] = useForm();
  const selectedPet = isEditModal(props) ? props.selectedPet : null;

  useEffect(() => {
    if (!isVisible) {
      form.resetFields(); // Reset form on modal close
      setFileList([]); // Clear file list on modal close
    } else if (modalType === ModalType.EDIT && selectedPet) {
      form.setFieldsValue({
        ...selectedPet,
        birthday: dayjs(selectedPet.birthday), // Ensure birthday is in dayjs format
      });
    }
  }, [isVisible, form, modalType, selectedPet]);
  

  const handleAdd = async () => {
    if (!isAddModal(props)) {
      return;
    }
  
    try {
      const values = await form.validateFields();
  
      if (!fileList.length) {
        message.error("Please upload a photo!");
        return;
      }
  
      if (!user || !user.sub) {
        message.error("User ID is missing. Please log in.");
        return;
      }
  
      const formData = new FormData();
      for (let key in values) {
        formData.append(key, values[key]);
      }
  
      // Append the photo using the utility function
      const photoData = preparePhotoData(fileList);
      if (photoData) {
        formData.append("photo", photoData);
      }
      formData.append("userId", user.sub);
  
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/api/pets/pets`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      message.success("Pet added successfully!");
      props.addNewPet(response.data);
      closeModal();
    } catch (error) {
      message.error("Error adding the pet. Please try again.");
    }
  };
  
  const handleEdit = async () => {
    if (!isEditModal(props)) {
      return;
    }
  
    try {
      const values = await form.validateFields();
  
      const formData = new FormData();
      for (let key in values) {
        if(key === "photo"){
          continue;
        }
        formData.append(key, values[key]);
      }
  
      // Get the new photo data using the utility function
      const photoData = preparePhotoData(fileList);
      if (photoData) {
        formData.append("photo", photoData); // Append the photo only if a new one is uploaded
      }
  
      // Prepare the PUT request
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URI}/api/pets/update/${props.selectedPet._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      message.success("Pet updated successfully");

      const updatedProps = response.data;
      props.onEdit(updatedProps);
      closeModal();
    } catch (error) {
      message.error("Error updating pet");
    }
  }; 
  
  const handleCancel = () => {
    closeModal();
    form.resetFields();
  };

  const handleUploadChange = ({ fileList }: any) => {
    setFileList(fileList);
  };

  const modalTitle = modalType === ModalType.EDIT ? "Edit Pet" : "Add New Pet";

  return (
    <Modal
      title={modalTitle}
      open={isVisible}
      onOk={modalType === ModalType.EDIT ? handleEdit : handleAdd}
      onCancel={handleCancel}
      okText={modalType === ModalType.EDIT ? "Update" : "Add"}
      cancelText="Cancel"
    >
      <Form
        form={form}
        layout="vertical"
        name="add_or_edit_pet_form"
        initialValues={{
          species: "dog",
          gender: "male (neutered)",
        }}
      >
        <Form.Item
          name="photo"
          label="Photo"
          rules={[{ required: modalType === ModalType.ADD, message: "Please upload a photo!" }]}
        >
          <Upload
            name="photo"
            listType="picture"
            fileList={fileList}
            beforeUpload={() => false}
            onChange={handleUploadChange}
            accept="image/*"
          >
            {fileList.length === 0 && (
              <Button icon={<UploadOutlined />}>Upload Photo</Button>
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input placeholder="Enter pet's name" />
        </Form.Item>

        <Form.Item
          name="birthday"
          label="Birthday"
          rules={[{ required: true, message: "Please select the birthday!" }]}
        >
          <DatePicker
            style={{ width: "100%" }}
            disabledDate={(current) => current && current > dayjs()}
          />
        </Form.Item>

        <Form.Item
          name="species"
          label="Species"
          rules={[{ required: true, message: "Please select the species!" }]}
        >
          <Select
            options={[
              { label: "Bird", value: "bird" },
              { label: "Cat", value: "cat" },
              { label: "Dog", value: "dog" },
              { label: "Other", value: "other" },
            ]}
          />
        </Form.Item>

        <Form.Item
          name="gender"
          label="Gender"
          rules={[{ required: true, message: "Please select the gender!" }]}
        >
          <Select
            options={[
              { label: "Male (Neutered)", value: "male (neutered)" },
              { label: "Male (Unneutered)", value: "male (unneutered)" },
              { label: "Female (Spayed)", value: "female (spayed)" },
              { label: "Female (Unspayed)", value: "female (unspayed)" },
            ]}
          />
        </Form.Item>

        <Form.Item
          name="breed"
          label="Breed"
          rules={[{ required: true, message: "Please input the breed!" }]}
        >
          <Input placeholder="Enter breed" />
        </Form.Item>

        <Form.Item
          name="weight"
          label="Weight (lbs)"
          rules={[{ required: true, message: "Please input the weight!" }]}
        >
          <Input type="number" placeholder="Enter weight" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

function isAddModal(modalProps: PetModalProps): modalProps is AddModalProps {
  return modalProps.modalType === ModalType.ADD;
}

function isEditModal(modalProps: PetModalProps): modalProps is EditModalProps {
  return modalProps.modalType === ModalType.EDIT;
}

function preparePhotoData(fileList: any[]) {
  if (fileList.length > 0 && fileList[0]?.originFileObj) {
    return fileList[0].originFileObj; // Return the file object if valid
  }
  return null; // Return null if there's no new photo
}

