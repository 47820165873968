import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  ReactNode,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { message } from "antd";

interface Inventory {
  _id: string;
  quantity: number;
  variation: string;
}

interface CartItemsType {
  _id: string;
  animalType: string;
  category: string;
  description: string;
  imageUrl?: string;
  imageUrl2?: string;
  imageUrl3?: string;
  imageUrl4?: string;
  inventory: Inventory[];
  price: number;
  reservedInventory: Inventory[];
  stripe_price_id: string;
  subCategory: string;
  title: string;
  updatedAt: string;
}

interface BookingItem {
  _id: string;
  serviceType: string;
  providerId: string;
  providerName: string;
  price: number;
  date: string;
  time: string;
  pet: string;
  addons: string[];
}

interface ProductItem {
  cartItem: CartItemsType;
  quantity: number;
  variation: string;
  _id: string;
}

interface CartResponse {
  products: ProductItem[];
  bookings: BookingItem[];
}

interface ShopContextType {
  products: ProductItem[];
  cartItems: ProductItem[];
  setProducts: React.Dispatch<React.SetStateAction<ProductItem[]>>;
  setCartItems: React.Dispatch<React.SetStateAction<ProductItem[]>>;
  bookings: BookingItem[];
  selectedProduct: any | null;
  setSelectedProduct: (product: any | null) => void;
  addToCart: (
    userId: string,
    itemId: string,
    selectedVariation: any,
    quantity: number
  ) => Promise<any>;
  removeFromCart: (itemId: string, type: 'product' | 'booking') => Promise<void>;
  increaseQuantity: (itemId: string, variation: any) => Promise<void>;
  decreaseQuantity: (itemId: string, variation: any) => Promise<void>;
  getTotalCartAmount: () => Promise<number>;
  fetchAndUpdateTotalCartItems: () => Promise<number>;
  totalCartItems: number;
  isAuthenticated: boolean;
  user: any;
  isLoading: boolean;
  userId: string | null;
  fetchCartItems: () => Promise<void>;
}

// Create ShopContext
export const ShopContext = createContext<ShopContextType>({
  products: [],
  cartItems: [],
  setProducts: () => {},
  setCartItems: () => {},
  bookings: [],
  selectedProduct: null,
  setSelectedProduct: () => {},
  addToCart: async () => {},
  removeFromCart: async () => {},
  increaseQuantity: async () => {},
  decreaseQuantity: async () => {},
  getTotalCartAmount: async () => 0,
  fetchAndUpdateTotalCartItems: async () => 0,
  totalCartItems: 0,
  isAuthenticated: false,
  user: null,
  isLoading: false,
  userId: null,
  fetchCartItems: async () => {},
});

// Custom hook to access the ShopContext
export const useShopContext = () => {
  const context = useContext(ShopContext);
  if (!context) {
    throw new Error("useShopContext must be used within a ShopContextProvider");
  }
  return context;
};

// ShopContextProvider component
interface ShopContextProviderProps {
  children: ReactNode;
}

const ShopContextProvider: React.FC<ShopContextProviderProps> = ({
  children,
}) => {
  const [products, setProducts] = useState<ProductItem[]>([]);
  const [cartItems, setCartItems] = useState<ProductItem[]>([]);
  const [bookings, setBookings] = useState<BookingItem[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
  const [totalCartItems, setTotalCartItems] = useState<number>(0); // state for total cart items
  const [userId, setUserId] = useState<string | null>(null);
  const { isAuthenticated, isLoading, user } = useAuth0();

  // Fetch products
  const fetchProducts = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/api/products`
      );
      if (!response.ok) throw new Error("Failed to fetch products");
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  }, []);

  // Fetch cart items
  const fetchCartItems = useCallback(async () => {
    if (!userId) return;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/api/cart/getCart/${userId}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch cart");
      const cartData = await response.json() || {} as CartResponse;
      setCartItems(cartData?.products || []);
      setBookings(cartData?.bookings || []);
      return cartData;
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  }, [userId]);

  // Get total cart items (including bookings)
  const fetchAndUpdateTotalCartItems = useCallback(async () => {
    let totalItems = 0;
    const cartData = await fetchCartItems();

    cartData?.products?.forEach((item: any) => {
      totalItems += item.quantity;
    });
    totalItems += cartData?.bookings?.length || 0;

    setTotalCartItems(totalItems);
    return totalItems;
  }, [fetchCartItems]);

  useEffect(() => {
    if (isAuthenticated && user?.sub) {
      setUserId(user.sub);
      fetchCartItems();
      fetchProducts();
      fetchAndUpdateTotalCartItems();
    }
  }, [fetchCartItems, fetchProducts, fetchAndUpdateTotalCartItems, isAuthenticated, user]);

  useEffect(() => {
    fetchAndUpdateTotalCartItems();
  }, [bookings.length, cartItems.length, fetchAndUpdateTotalCartItems]);

  // Add to cart
  const addToCart = async (
    userId: string,
    itemId: string,
    selectedVariation: any,
    quantity: number
  ) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/api/cart/addToCart`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId,
            cartItem: itemId,
            quantity,
            variation: selectedVariation,
          }),
        }
      );
      if (!response.ok) throw new Error("Failed to add item to cart");
      await fetchCartItems();
    } catch (error) {
      console.error("Error adding item to cart:", error);
      message.error('Error adding item to cart');
    }
  };

  // Increase quantity
  const increaseQuantity = async (itemId: string, variation: any) => {
    await changeQuantity(itemId, variation, "increase");
  };

  // Decrease quantity
  const decreaseQuantity = async (itemId: string, variation: any) => {
    await changeQuantity(itemId, variation, "decrease");
  };

  // Helper: Change quantity
  const changeQuantity = async (
    itemId: string,
    variation: any,
    method: "increase" | "decrease"
  ) => {
    const cartItemToUpdate = cartItems.find(
      (item) => item.cartItem["_id"] === itemId
    );

    if (!cartItemToUpdate) {
      message.error("Could not find cart item.");
      return;
    }

    const newQuantity =
      method === "increase"
        ? cartItemToUpdate.quantity + 1
        : cartItemToUpdate.quantity - 1;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/api/cart/changeQuantity`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId,
            cartItem: itemId,
            newQuantity,
            variation,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to update item quantity");

      // Fetch updated cart items after successfully updating the quantity
      await fetchCartItems();

      // Fetch and update the total cart items count
      await fetchAndUpdateTotalCartItems();
    } catch (error) {
      console.error("Error updating item quantity:", error);
    }
  };

  // Get total cart amount (including bookings)
  const getTotalCartAmount = useCallback(async () => {
    if (!userId) return 0;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/api/cart/find/${userId}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch cart");
      const cartData = await response.json();
      let totalAmount = 0;

      cartData?.products?.forEach((item: any) => {
        totalAmount += item.quantity * item.cartItem.price;
      });

      cartData?.bookings?.forEach((booking: any) => {
        totalAmount += booking.price;
      });

      return totalAmount;
    } catch (error) {
      console.error("Error fetching total cart amount:", error);
      return 0;
    }
  }, [userId]);

  // Remove from cart
  const removeFromCart = async (itemId: string, type: 'product' | 'booking') => {
    if(!userId){
      return;
    }

    const suffix = type === 'product' ? '/deleteProduct' : '/deleteBooking';
    const body: Record<string, string> = {
      userId
    };
    const key = type === 'product' ? 'cartItem' : 'bookingId';
    body[key] = itemId;

    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/api/cart/${suffix}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      await fetchCartItems();
    } catch (error) {
      console.error("Error removing item from cart:", error);
    }
  };

  // Provide context value
  const contextValue: ShopContextType = {
    products,
    setProducts,
    cartItems,
    setCartItems,
    bookings,
    selectedProduct,
    setSelectedProduct,
    addToCart,
    removeFromCart,
    increaseQuantity,
    decreaseQuantity,
    getTotalCartAmount,
    fetchAndUpdateTotalCartItems,
    totalCartItems,
    isAuthenticated,
    user,
    isLoading,
    userId,
    fetchCartItems,
  };

  return (
    <ShopContext.Provider value={contextValue}>{children}</ShopContext.Provider>
  );
};

export default ShopContextProvider;
