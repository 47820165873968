import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import {
  Typography,
  Row,
  Col,
  Card,
  Pagination,
  Modal,
  Select,
  Button,
  Carousel,
} from "antd";
import Spacer from "../Spacer/Spacer";
import "./PawFinderMap.css";

const { Title, Text, Link } = Typography;
const { Option } = Select;

interface Location {
  coordinates: { lat: number; lng: number };
}

interface Address {
  street: string;
  city: string;
  provinceOrState: string;
  country: string;
}

interface Pet {
  _id: string;
  name: string;
  species: string;
  breed: string;
  weight: number;
  location: Location;
  address: Address;
  photoUrls?: string[];
  birthday?: string;
}

interface Shelter {
  _id: string;
  name: string;
  contactPerson: string;
  contactPersonEmail: string;
  generalPhoneNumber: string;
  mainWebsite: string;
  speciesCateredFor: string[];
  hoursOfOperation: string;
  socialMediaLinks?: {
    facebook?: string;
    instagram?: string;
    x?: string;
  };
  address: Address;
  location: Location;
}

type LocationData = Pet | Shelter;

const countryStateData: Record<string, string[]> = {
  Canada: ["Ontario", "Quebec", "British Columbia", "Alberta"],
  USA: ["California", "New York", "Texas", "Florida"],
  // Add more countries and states/provinces as needed
};

const PawFinderMap: React.FC = () => {
  const [shelters, setShelters] = useState<Shelter[]>([]);
  const [pets, setPets] = useState<Pet[]>([]);
  const [currentShelterPage, setCurrentShelterPage] = useState(1);
  const [currentPetPage, setCurrentPetPage] = useState(1);
  const [selectedLocation, setSelectedLocation] = useState<LocationData | null>(
    null
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const sheltersPerPage = 9;
  const petsPerPage = 9;
  const [infoWindowOpen, setInfoWindowOpen] = useState<string | null>(null);

  const mapRef = useRef<google.maps.Map | null>(null); 

  useEffect(() => {
    const fetchShelters = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URI}/api/shelter/shelters`
        );
        const data = await response.json();
        setShelters(data);
      } catch (error) {
        console.error("Failed to fetch shelters:", error);
      }
    };

    const fetchPets = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URI}/api/lostPet/lostPets`
        );
        const data = await response.json();
        setPets(data);
      } catch (error) {
        console.error("Failed to fetch pets:", error);
      }
    };

    fetchShelters();
    fetchPets();
  }, []);

  const handleCardClick = (location: Location, data: LocationData) => {
    if (mapRef.current) {
      mapRef.current.panTo(location.coordinates);
      mapRef.current.setZoom(15);
    }
    setSelectedLocation(data);
    setInfoWindowOpen(data._id);
    setIsModalVisible(true);
  };

  const handleCountryChange = (value: string) => {
    setCountry(value);
    setState(""); // Reset state when country changes
  };

  const handleFilter = () => {
    const filteredPets = pets.filter(
      (pet) =>
        (country === "" || pet.address.country === country) &&
        (state === "" || pet.address.provinceOrState === state)
    );
    const filteredShelters = shelters.filter(
      (shelter) =>
        (country === "" || shelter.address.country === country) &&
        (state === "" || shelter.address.provinceOrState === state)
    );
    setPets(filteredPets);
    setShelters(filteredShelters);
  };

  const isPet = (location: LocationData): location is Pet => {
    return (location as Pet).species !== undefined;
  };

  const isShelter = (location: LocationData): location is Shelter => {
    return (location as Shelter).contactPerson !== undefined;
  };

  const mapContainerStyle = {
    height: "400px",
    width: "100%",
  };

  const center = {
    lat: 45.4215,
    lng: -75.6972,
  };

  return (
    <div className="pawfinder-container">
      <div className="filter-bar" style={{ marginBottom: "20px" }}>
        <Select
          style={{ width: 200, marginRight: "10px" }}
          placeholder="Select Country"
          onChange={handleCountryChange}
          value={country}
        >
          {Object.keys(countryStateData).map((country) => (
            <Option key={country} value={country}>
              {country}
            </Option>
          ))}
        </Select>
        <Select
          style={{ width: 200, marginRight: "10px" }}
          placeholder="Select State/Province"
          onChange={(value) => setState(value)}
          value={state}
          disabled={!country}
        >
          {country &&
            countryStateData[country]?.map((state) => (
              <Option key={state} value={state}>
                {state}
              </Option>
            ))}
        </Select>
        <Button type="primary" onClick={handleFilter}>
          Filter
        </Button>
      </div>

      <div className="map-container">
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
        >
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={7}
            onLoad={(map) => {
              mapRef.current = map; 
            }}
          >
            {selectedLocation && (
              <Marker
                position={selectedLocation.location.coordinates}
                onClick={() => setInfoWindowOpen(selectedLocation._id)}
              >
                {infoWindowOpen === selectedLocation._id && (
                  <InfoWindow onCloseClick={() => setInfoWindowOpen(null)}>
                    <div>
                      {isPet(selectedLocation) ? (
                        <>
                          <strong>{selectedLocation.name}</strong>
                          <br />
                          Species: {selectedLocation.species}
                          <br />
                          Breed: {selectedLocation.breed}
                          <br />
                          Weight: {selectedLocation.weight} kg
                          <br />
                          Last Seen: {selectedLocation.address?.street},{" "}
                          {selectedLocation.address?.city},{" "}
                          {selectedLocation.address?.provinceOrState},{" "}
                          {selectedLocation.address?.country}
                        </>
                      ) : (
                        <>
                          <strong>{selectedLocation.name}</strong>
                          <br />
                          Contact: {selectedLocation.contactPerson}
                          <br />
                          Email:{" "}
                          <Link
                            href={`mailto:${selectedLocation.contactPersonEmail}`}
                            target="_blank"
                          >
                            {selectedLocation.contactPersonEmail}
                          </Link>
                          <br />
                          Phone: {selectedLocation.generalPhoneNumber}
                          <br />
                          Address: {selectedLocation.address?.street},{" "}
                          {selectedLocation.address?.city},{" "}
                          {selectedLocation.address?.provinceOrState},{" "}
                          {selectedLocation.address?.country}
                        </>
                      )}
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            )}
          </GoogleMap>
        </LoadScript>
      </div>
      <Spacer />
      <div className="info-container">
        <Title level={4}>Lost Pets Reported</Title>
        <Row gutter={[16, 16]}>
          {pets.map((pet) => (
            <Col span={8} key={pet._id}>
              <Card
                title={pet.name}
                onClick={() => handleCardClick(pet.location, pet)}
                hoverable
                style={{
                  cursor: "pointer",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                <p>
                  <Text strong>Species:</Text> {pet.species}
                </p>
                <p>
                  <Text strong>Breed:</Text> {pet.breed}
                </p>
                <p>
                  <Text strong>Weight:</Text> {pet.weight} kg
                </p>
                <p>
                  <Text strong>Seen Location:</Text> {pet.address?.street},{" "}
                  {pet.address?.city}, {pet.address?.provinceOrState}
                </p>
              </Card>
            </Col>
          ))}
        </Row>
        <Pagination
          current={currentPetPage}
          pageSize={petsPerPage}
          total={pets.length}
          onChange={setCurrentPetPage}
          style={{ marginTop: "16px", textAlign: "center" }}
        />

        <Title level={4} style={{ marginTop: "32px" }}>
          Shelters and Lost Pet Homes
        </Title>
        <Row gutter={[16, 16]}>
          {shelters.map((shelter) => (
            <Col span={8} key={shelter._id}>
              <Card
                title={shelter.name}
                onClick={() => handleCardClick(shelter.location, shelter)}
                hoverable
                style={{
                  cursor: "pointer",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                <p>
                  <Text strong>Contact:</Text> {shelter.contactPerson}
                </p>
                <p>
                  <Text strong>Email:</Text>{" "}
                  <Link
                    href={`mailto:${shelter.contactPersonEmail}`}
                    target="_blank"
                  >
                    {shelter.contactPersonEmail}
                  </Link>
                </p>
                <p>
                  <Text strong>Phone:</Text> {shelter.generalPhoneNumber}
                </p>
                <p>
                  <Text strong>Website:</Text>{" "}
                  <Link href={shelter.mainWebsite} target="_blank">
                    {shelter.mainWebsite}
                  </Link>
                </p>
                <p>
                  <Text strong>Address:</Text> {shelter.address?.street},{" "}
                  {shelter.address?.city}, {shelter.address?.provinceOrState},{" "}
                  {shelter.address?.country}
                </p>
              </Card>
            </Col>
          ))}
        </Row>
        <Pagination
          current={currentShelterPage}
          pageSize={sheltersPerPage}
          total={shelters.length}
          onChange={setCurrentShelterPage}
          style={{ marginTop: "16px", textAlign: "center" }}
        />
      </div>

      <Modal
        title={selectedLocation?.name}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        {selectedLocation &&
          isPet(selectedLocation) &&
          selectedLocation.photoUrls && (
            <Carousel autoplay>
              {selectedLocation.photoUrls.map((url, index) => (
                <div key={index}>
                  <img
                    src={url}
                    alt={`Photo${index + 1}`}
                    style={{
                      width: "100%",
                      maxHeight: "300px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              ))}
            </Carousel>
          )}
        {selectedLocation && isPet(selectedLocation) && (
          <>
            <p>
              <Text strong>Species:</Text> {selectedLocation.species}
            </p>
            <p>
              <Text strong>Breed:</Text> {selectedLocation.breed}
            </p>
            <p>
              <Text strong>Weight:</Text> {selectedLocation.weight} kg
            </p>
            {selectedLocation.birthday && (
              <p>
                <Text strong>Birthday:</Text>{" "}
                {new Date(selectedLocation.birthday).toLocaleDateString()}
              </p>
            )}
            <p>
              <Text strong>Last Seen:</Text> {selectedLocation.address?.street},{" "}
              {selectedLocation.address?.city},{" "}
              {selectedLocation.address?.provinceOrState},{" "}
              {selectedLocation.address?.country}
            </p>
          </>
        )}
        {selectedLocation && isShelter(selectedLocation) && (
          <>
            <p>
              <Text strong>Contact:</Text> {selectedLocation.contactPerson}
            </p>
            <p>
              <Text strong>Email:</Text>{" "}
              <Link
                href={`mailto:${selectedLocation.contactPersonEmail}`}
                target="_blank"
              >
                {selectedLocation.contactPersonEmail}
              </Link>
            </p>
            <p>
              <Text strong>Phone:</Text> {selectedLocation.generalPhoneNumber}
            </p>
            <p>
              <Text strong>Website:</Text>{" "}
              <Link href={selectedLocation.mainWebsite} target="_blank">
                {selectedLocation.mainWebsite}
              </Link>
            </p>
            <p>
              <Text strong>Species Catered For:</Text>{" "}
              {selectedLocation.speciesCateredFor?.join(", ")}
            </p>
            <p>
              <Text strong>Hours of Operation:</Text>{" "}
              {selectedLocation.hoursOfOperation}
            </p>
            {selectedLocation.socialMediaLinks && (
              <>
                <p>
                  <Text strong>Social Media:</Text>
                </p>
                {selectedLocation.socialMediaLinks.facebook && (
                  <p>
                    <Link
                      href={selectedLocation.socialMediaLinks.facebook}
                      target="_blank"
                    >
                      Facebook
                    </Link>
                  </p>
                )}
                {selectedLocation.socialMediaLinks.instagram && (
                  <p>
                    <Link
                      href={selectedLocation.socialMediaLinks.instagram}
                      target="_blank"
                    >
                      Instagram
                    </Link>
                  </p>
                )}
                {selectedLocation.socialMediaLinks.x && (
                  <p>
                    <Link
                      href={selectedLocation.socialMediaLinks.x}
                      target="_blank"
                    >
                      X (formerly Twitter)
                    </Link>
                  </p>
                )}
              </>
            )}
            <p>
              <Text strong>Address:</Text> {selectedLocation.address?.street},{" "}
              {selectedLocation.address?.city},{" "}
              {selectedLocation.address?.provinceOrState},{" "}
              {selectedLocation.address?.country}
            </p>
          </>
        )}
      </Modal>
    </div>
  );
};

export default PawFinderMap;
