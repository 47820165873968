import React from 'react';
import { Avatar, Typography, Row, Col } from 'antd';
import { TwitterOutlined, FacebookOutlined, InstagramOutlined } from '@ant-design/icons';
import './AuthorBio.css';
import { Link } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const AuthorBio: React.FC = () => {
  return (
    <section className="author-bio">
      <div className="author-bio-container">
        <div className="author author--featured">
          <Link to={'/'} className="author__img-wrapper">
            <Avatar
              size={120}
              src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1721315617/IMG_3568_udi2dp.jpg"
              alt="Author picture"
            />
          </Link>
          
          <div className="author__content">
            <Title level={2}>Anne Duret</Title>
            <Paragraph>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, accusantium consequatur. Perspiciatis!
            </Paragraph>
          </div>

          <Row className="author__social-links" gutter={[16, 16]} justify="center">
            <Col>
              <Link to={'/'} className="author__social">
                <TwitterOutlined className="author__social-icon" title="Follow the author on Twitter" />
              </Link>
            </Col>
            <Col>
              <Link to={'/'} className="author__social">
                <FacebookOutlined className="author__social-icon" title="Follow the author on Facebook" />
              </Link>
            </Col>
            <Col>
              <Link to={'/'} className="author__social">
                <InstagramOutlined className="author__social-icon" title="Follow the author on Instagram" />
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default AuthorBio;
