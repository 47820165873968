import React from "react";
import "./scss/Stories.scss"; // Make sure to import your CSS file here
import "../../assets/styles/global.css";
import { Link } from "react-router-dom";
import { Typography } from "antd";

const { Text, Title } = Typography;

// Define Story type for better type checking
interface Story {
  title: string;
  date: string;
  category: string;
  imageUrl: string;
  link: string;
}

const storiesData: Story[] = [
  {
    title: "A Companion for Life",
    date: "2020-05-10",
    category: "Personal Experience",
    imageUrl: "https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587569/13_e5agpf.png",
    link: "/",
  },
  {
    title: "The Calming Presence in the Storm",
    date: "2020-04-08",
    category: "Pet Care Tips",
    imageUrl: "https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587569/12_rnsjyk.png",
    link: "/",
  },
  {
    title: "Moonlit Adventures with My Pet",
    date: "2020-03-18",
    category: "Pet Adventures",
    imageUrl: "https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587568/6_ncqvyb.png",
    link: "/",
  },
  {
    title: "Two Natures, One Unforgettable Bond",
    date: "2020-03-05",
    category: "Pet Owner Reflection",
    imageUrl: "https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587569/9_ky6dpf.png",
    link: "/",
  },
];

const Stories: React.FC = () => {
  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
      <div className="stories">
        <h3 className="stories__legend">
          <Title level={4}>Popular stories</Title>
        </h3>

        <ol className="stories__list">
          {storiesData.map((story, index) => (
            <li key={index} className="stories__story">
              <Link to={story.link} className="stories__img-wrapper">
                <figure>
                  <img src={story.imageUrl} alt={story.title} />
                </figure>
              </Link>

              <div className="stories__text sz3-text-component sz3-line-height-md sz3-text-gap-2xs">
                <h4 className="stories__headline">
                  <Link to={story.link}>
                    <Text strong>{story.title}</Text>
                  </Link>
                </h4>
                <p className="stories__metadata">
                  <time dateTime={story.date}>{new Date(story.date).toLocaleDateString()}</time>
                  <span className="stories__separator" role="separator"></span>
                  <Link to={story.link}>{story.category}</Link>
                </p>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </section>
  );
};

export default Stories;
