import React from "react";
import { Typography } from "antd";
import {
  FundViewOutlined,
  EnvironmentOutlined,
  TeamOutlined,
  FrownOutlined,
  BookOutlined,
  TagsOutlined,
} from "@ant-design/icons";
import "./CareerBnPs.css"; // Assuming you have corresponding CSS
import Spacer from "../Spacer/Spacer";

const { Title, Text } = Typography;

interface BenefitItemProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const BenefitItem: React.FC<BenefitItemProps> = ({ icon, title, description }) => (
  <li className="feature-v20__item">
    <figure className="feature-v20__icon-wrapper">
      <span className="feature-v20__icon">{icon}</span>
    </figure>
    <div className="cd-text-center">
      <Title level={4} className="cd-margin-bottom-2xs feature-v20__item-title">
        {title}
      </Title>
      <Text strong className="feature-v20__paragraph">
        {description}
      </Text>
    </div>
  </li>
);

const CareerBnPs: React.FC = () => {
  return (
    <section className="feature-v20 cd-padding-y-xl">
      <div className="cd-container cd-max-width-adaptive-lg">
        <Title level={2} className="cd-margin-bottom-2xs cd-text-center">
          Advantages and Extras
        </Title>
        <Spacer />
        <ul className="feature-v20__list">
          <BenefitItem
            icon={<FundViewOutlined />}
            title="Health & Retirement Savings"
            description="Comprehensive health coverage for employees and matching contributions for retirement savings."
          />

          <BenefitItem
            icon={<EnvironmentOutlined />}
            title="Flexible Vacation & Company Holidays"
            description="Emphasis on work-life balance with flexible vacation policies and designated holidays"
          />

          <BenefitItem
            icon={<BookOutlined />}
            title="Learning & Development"
            description="Career growth opportunities, guest speaker events, and an annual education stipend."
          />
        </ul>
        <Spacer />
        <ul className="feature-v20__list">
          <BenefitItem
            icon={<FrownOutlined />}
            title="Mental Health & Wellness"
            description="Wellness stipend and complimentary access to mental health services."
          />

          <BenefitItem
            icon={<TeamOutlined />}
            title="Family Planning"
            description="Paid leave for new parents, adoption support, and fertility/reproductive healthcare."
          />

          <BenefitItem
            icon={<TagsOutlined />}
            title="Perks and Allowances"
            description="Employee discounts, credits for Paw Pros services, and stipends to aid remote work."
          />
        </ul>
      </div>
    </section>
  );
};

export default CareerBnPs;
