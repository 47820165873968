import React from 'react';
import { Typography } from 'antd';
import { PercentageOutlined, SmileOutlined, RiseOutlined } from '@ant-design/icons';
import './BappBenefits.css'; // Assuming you have corresponding CSS

const { Title, Text } = Typography;

interface BenefitItemProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const BenefitItem: React.FC<BenefitItemProps> = ({ icon, title, description }) => (
  <li className="feature-v20__item">
    <figure className="feature-v20__icon-wrapper">
      <span className="feature-v20__icon">{icon}</span>
    </figure>
    <div className="cd-text-center">
      <Title level={4} className="cd-margin-bottom-2xs feature-v20__item-title">{title}</Title>
      <Text strong className="feature-v20__paragraph">{description}</Text>
    </div>
  </li>
);

const BappBenefits: React.FC = () => {
  return (
    <section className="feature-v20 cd-padding-y-xl">
      <div className="cd-container cd-max-width-adaptive-lg">
        <ul className="feature-v20__list">
          <BenefitItem
            icon={<PercentageOutlined />}
            title="Set your own rates and pricing"
            description="Personalize your pricing to reflect the value of your services. Clients pay upfront before each service, ensuring seamless transactions and peace of mind."
          />

          <BenefitItem
            icon={<SmileOutlined />}
            title="Become self-employed"
            description="Take charge of your pet care business with the freedom to work on your terms. Offer services across twelve distinct categories, giving you flexibility in scheduling and service location."
          />

          <BenefitItem
            icon={<RiseOutlined />}
            title="Grow your business exponentially"
            description="Connect with pet owners in your local community and access marketing resources to enhance your visibility. Focus on providing exceptional care while we support your business development."
          />
        </ul>
      </div>
    </section>
  );
};

export default BappBenefits;
