import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Typography, Button, Modal, message, Spin } from 'antd';
import axios from 'axios';
import { useBookingContext } from '../../context/BookingContext';
import './scss/CaregiverDisplay.scss';

const { Title, Paragraph, Text } = Typography;

interface Caregiver {
  _id: string;
  providerId: string;
  firstName: string;
  lastName: string;
  email: string;
  experienceLevel: string;
  essentialsPrice?: number; // Will be dynamically fetched based on service
  profilePictureUrl: string;
}

interface PricingResponse {
  services: {
    key: string;
    essentials: { price: number };
  }[];
}

interface AvailabilityResponse {
  availability: {
    day: string;
    times: { startTime: string; endTime: string }[];
  }[];
}

interface ReservedTime {
  userId: string;
  date: string;
  time: string;
  paymentStatus: string;
}

interface CaregiverDisplayProps {
  selectedService: string;
  onProviderSelect: (caregiver: Caregiver) => void;
}

// Helper function to convert service names to camelCase
const toCamelCase = (str: string): string => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase()
    )
    .replace(/\s+/g, '');
};

const CaregiverDisplay: React.FC<CaregiverDisplayProps> = ({ selectedService, onProviderSelect }) => {
  const [caregivers, setCaregivers] = useState<Caregiver[]>([]);
  const [availableCaregivers, setAvailableCaregivers] = useState<Caregiver[]>([]);
  const [unavailableCaregivers, setUnavailableCaregivers] = useState<Caregiver[]>([]);
  const [selectedCaregiver, setSelectedCaregiver] = useState<Caregiver | null>(null);
  const [isUnavailableModalVisible, setIsUnavailableModalVisible] = useState(false);
  const { bookingDetails } = useBookingContext();
  const [loading, setLoading] = useState(false);

  // Fetch caregiver pricing
  const fetchCaregiverPricing = async (providerId: string, serviceKey: string) => {
    try {
      const response = await axios.get<PricingResponse>(`${process.env.REACT_APP_BACKEND_URI}/api/pricing/provider-pricing/${providerId}`);
      const service = response.data.services.find((s) => s.key === serviceKey);
      return service?.essentials.price || 'N/A'; // Return essentials price
    } catch (error) {
      console.error('Error fetching caregiver pricing:', error);
      return 'N/A'; // Fallback value if there's an error
    }
  };

  // Fetch caregivers based on the selected service
  useEffect(() => {
    const fetchCaregivers = async () => {
      try {
        const serviceKey = toCamelCase(selectedService);
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/api/communityCaregiver/service/${serviceKey}`);
        const caregiversWithPricing = await Promise.all(
          response.data.map(async (caregiver: Caregiver) => {
            const essentialsPrice = await fetchCaregiverPricing(caregiver.providerId, serviceKey);
            console.log(caregiver.providerId);
            return { ...caregiver, essentialsPrice };
            
          })
        );
        setCaregivers(caregiversWithPricing);
      } catch (error) {
        console.error('Error fetching community caregivers:', error);
        message.error('Error fetching caregivers.');
      } finally {
        setLoading(false);
      }
    };

    if (selectedService) {
      fetchCaregivers();
      setLoading(true);
    }
  }, [selectedService]);

  // Check availability and reserved times
  const checkAvailability = useCallback(async () => {
    if (!bookingDetails.date || !bookingDetails.time || !bookingDetails.dayOfWeek) {
      return;
    }

    const available: Caregiver[] = [];
    const unavailable: Caregiver[] = [];

    for (const caregiver of caregivers) {
      try {
        const providerId = caregiver.providerId;
        const bookingDate = new Date(bookingDetails.date).toISOString().split('T')[0]; // Format date as 'YYYY-MM-DD'
        const bookingTime = new Date(bookingDetails.time).toLocaleTimeString('en-US', { hour12: false }); // Time in 'HH:MM' format
        const bookingDayOfWeek = bookingDetails.dayOfWeek; // Day of the week (e.g., 'Monday')

        // 1. Check Reserved Times
        const reservedTimesResponse = await axios.get<{ reservedTimes: ReservedTime[] }>(
          `${process.env.REACT_APP_BACKEND_URI}/api/reserved-times/reserved-times/${providerId}`
        );

        const isTimeReserved = reservedTimesResponse.data.reservedTimes.some(
          (reservation) => reservation.date === bookingDate && reservation.time === bookingTime
        );

        if (isTimeReserved) {
          unavailable.push(caregiver);
          continue; // Skip to the next caregiver
        }

        // 2. Check Provider Availability
        const availabilityResponse = await axios.get<AvailabilityResponse>(
          `${process.env.REACT_APP_BACKEND_URI}/api/provider-availability/availability/${providerId}`
        );

        const dayAvailability = availabilityResponse.data.availability.find(
          (day) => day.day === bookingDayOfWeek
        );

        if (!dayAvailability) {
          unavailable.push(caregiver);
          continue;
        }

        // Check if the booking time falls within any of the available time ranges
        const isTimeAvailable = dayAvailability.times.some((timeRange) => {
          return bookingTime >= timeRange.startTime && bookingTime <= timeRange.endTime;
        });

        if (isTimeAvailable) {
          available.push(caregiver);
        } else {
          unavailable.push(caregiver);
        }
      } catch (error) {
        console.error('Error checking caregiver availability:', error);
        unavailable.push(caregiver);
      }
    }

    setAvailableCaregivers(available);
    setUnavailableCaregivers(unavailable);
  }, [caregivers, bookingDetails.date, bookingDetails.time, bookingDetails.dayOfWeek]);

  useEffect(() => {
    if (caregivers.length > 0) {
      checkAvailability();
    }
  }, [caregivers, bookingDetails.date, bookingDetails.time, bookingDetails.dayOfWeek, checkAvailability]);

  // Handle selecting a caregiver
  const handleSelectCaregiver = (caregiver: Caregiver) => {
    setSelectedCaregiver(caregiver);
    onProviderSelect(caregiver);
  };

  // Modal handling for unavailable caregivers
  const showUnavailableCaregivers = () => {
    setIsUnavailableModalVisible(true);
  };

  const handleModalClose = () => {
    setIsUnavailableModalVisible(false);
  };

  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
      {loading ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Spin size="large" />
        </div>
      ) : availableCaregivers.length === 0 ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Text>No certified partners available for this service.</Text>
        </div>
      ) : (
      <Row gutter={[16, 16]}>
        {availableCaregivers.map((caregiver) => (
          <Col key={caregiver._id} xs={24} sm={12} md={8} lg={6}>
            <Card
              hoverable
              className={selectedCaregiver && selectedCaregiver._id === caregiver._id ? 'selected-card' : ''}
              onClick={() => handleSelectCaregiver(caregiver)}
            >
              <img src={caregiver.profilePictureUrl} alt={caregiver.firstName} style={{ width: '100%' }} />
              <div>
                <Title level={3}>{`${caregiver.firstName} ${caregiver.lastName}`}</Title>
                <Paragraph>Email: {caregiver.email}</Paragraph>
                <Text strong>Experience Level:</Text>
                <Paragraph>{caregiver.experienceLevel}</Paragraph>
                <Text strong>Starting at Price:</Text>
                <Paragraph>${caregiver.essentialsPrice || 'N/A'}</Paragraph>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
      )}

      {unavailableCaregivers.length > 0 && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button onClick={showUnavailableCaregivers}>Show Unavailable Caregivers</Button>
        </div>
      )}

      <Modal visible={isUnavailableModalVisible} onCancel={handleModalClose} footer={null}>
        <Title level={4}>Unavailable Caregivers</Title>
        <Row gutter={[16, 16]}>
          {unavailableCaregivers.map((caregiver) => (
            <Col key={caregiver._id} xs={24} sm={12} md={8} lg={6}>
              <Card hoverable>
                <img src={caregiver.profilePictureUrl || 'placeholder_image_url'} alt={caregiver.firstName} style={{ width: '100%' }} />
                <div>
                  <Title level={4}>{`${caregiver.firstName} ${caregiver.lastName}`}</Title>
                  <Paragraph>Email: {caregiver.email}</Paragraph>
                  <Text strong>Experience Level:</Text>
                  <Paragraph>{caregiver.experienceLevel}</Paragraph>
                  <Text strong>Starting at Price:</Text>
                  <Paragraph>${caregiver.essentialsPrice || 'N/A'}</Paragraph>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Modal>
    </section>
  );
};

export default CaregiverDisplay;
