import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Typography, Checkbox, Button, message } from 'antd';
import { useBookingContext } from '../../context/BookingContext'; // Import BookingContext
import './ProviderPricingDisplay.css';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

interface AddOn {
  title: string;
  description: string;
  price: number;
}

interface ServiceTier {
  description: string;
  price: number;
}

interface Service {
  key: string;
  label: string;
  essentials: ServiceTier;
  petPlus: ServiceTier;
  pawfection: ServiceTier;
  addOns: AddOn[];
}

interface ProviderPricingResponse {
  providerId: string;
  services: Service[];
}

// Type guard to check if the value is a ServiceTier
const isServiceTier = (tier: any): tier is ServiceTier => {
  return tier && typeof tier.price === 'number' && typeof tier.description === 'string';
};

const toCamelCase = (str: string): string => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase()
    )
    .replace(/\s+/g, '');
};

const ProviderPricingDisplay: React.FC = () => {
  const { selectedProvider, selectedService, addToCart, updateBookingDetails } = useBookingContext(); // Access selectedProvider and addToCart
  const [serviceData, setServiceData] = useState<Service | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [selectedAddOns, setSelectedAddOns] = useState<number[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedProvider || !selectedService) {
      console.warn('Provider or service is missing:', { selectedProvider, selectedService });
      return;
    }

    const fetchPricingData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URI}/api/pricing/provider-pricing/${selectedProvider.providerId}`
        );
        if (!response.ok) throw new Error('Failed to fetch pricing data');
        const data: ProviderPricingResponse = await response.json();

        const serviceKey = toCamelCase(selectedService);
        const matchedService = data.services.find((service) => service.key === serviceKey);

        if (matchedService) {
          setServiceData(matchedService);
        } else {
          message.error('Selected service not found for this provider');
        }
      } catch (error) {
        console.error('Error fetching pricing data:', error);
        message.error('Failed to fetch pricing data');
      }
    };

    fetchPricingData();
  }, [selectedProvider, selectedService]);

  if (!selectedProvider || !selectedService) {
    return <div>Loading provider and service...</div>;
  }

  if (!serviceData) {
    return <div>Loading service data...</div>;
  }

  const handlePlanSelect = (planTitle: keyof Service) => {
    setSelectedPlan(planTitle);
    
    const selectedTier = serviceData[planTitle];
  
    // Check if selectedTier is a ServiceTier before trying to access the price property
    if (isServiceTier(selectedTier)) {
      updateBookingDetails('price', selectedTier.price); // Update price in the context
    } else {
      console.error('Selected plan is not a valid ServiceTier');
    }
  };
  

  const handleAddonSelect = (addonIndex: number) => {
    setSelectedAddOns((prev) =>
      prev.includes(addonIndex)
        ? prev.filter((index) => index !== addonIndex)
        : [...prev, addonIndex]
    );
    updateBookingDetails('addons', selectedAddOns); // Update add-ons in the context
  };

  const handleAddToCartClick = async () => {
    
    if (!selectedPlan) {
      message.error('Please select a pricing plan before adding to cart');
      return;
    }
  
    // Log provider details before adding to cart
    console.log('Provider ID:', selectedProvider?.providerId);
    console.log('Provider Name:', selectedProvider?.name);
  
    if (!selectedProvider || !selectedProvider.providerId || !selectedProvider.name) {
      message.error('Provider details are missing');
      return;
    }
  
    try {
      await addToCart(); // Assuming addToCart is from the BookingContext
      navigate('/cart')
    } catch (error) {
      message.error('Error adding booking to cart');
      console.error('Error adding booking to cart:', error);
    }
  };
  
  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
      <div className="pricing-display-container">
        {/* Display the Pricing Tiers */}
        <Row gutter={[16, 16]}>
          {['essentials', 'petPlus', 'pawfection'].map((tier) => {
            const tierData = serviceData[tier as keyof Service];

            if (isServiceTier(tierData)) {
              return (
                <Col xs={24} md={8} key={tier}>
                  <Card
                    className={`pricing-card ${selectedPlan === tier ? 'selected' : ''}`}
                    onClick={() => handlePlanSelect(tier as keyof Service)}
                    hoverable
                  >
                    <Title level={4} className="pricing-title">
                      {tier.charAt(0).toUpperCase() + tier.slice(1)}
                    </Title>
                    <div className="pricing-amount">
                      <span>${tierData.price}</span>
                    </div>
                    <Text className="pricing-description">{tierData.description}</Text>
                  </Card>
                </Col>
              );
            }
            return null;
          })}
        </Row>

        {/* Display the Add-ons */}
        {serviceData.addOns && serviceData.addOns.length > 0 && (
          <div className="addons-section">
            <Title level={4}>Select Add-ons</Title>
            <Row gutter={[16, 16]}>
              {serviceData.addOns.map((addon, index) => (
                <Col xs={24} md={8} key={index}>
                  <Card className="addon-card">
                    <Checkbox
                      checked={selectedAddOns.includes(index)}
                      onChange={() => handleAddonSelect(index)}
                    >
                      <div className="addon-info">
                        <Text strong>{addon.title}</Text> - ${addon.price}
                        <br />
                        <Text type="secondary">{addon.description}</Text>
                      </div>
                    </Checkbox>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        )}

        <div className="add-to-cart-section">
          <Button type="primary" onClick={handleAddToCartClick} className="add-to-cart-button">
            Add to Cart
          </Button>
        </div>
      </div>
    </section>
  );
};

export default ProviderPricingDisplay;
