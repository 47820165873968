// themeConfig.ts
export const lightTheme = {
  '@primary-color': '#ff4da6', // Electric Pink
  '@link-color': '#4d9fff', // Neon Blue
  '@success-color': '#4caf50', // Green for success states
  '@warning-color': '#ff9800', // Orange for warning
  '@error-color': '#f5222d', // Red for error states
  '@font-family': '"Poppins", "Roboto", sans-serif',
  '@heading-color': '#1a1a2e', // Dark navy for headings
  '@text-color': '#333', // Default text color
  '@text-color-secondary': '#555', // Secondary text color
  '@border-radius-base': '10px', // Rounded corners for components
  '@border-color-base': '#d9d9d9', // Light gray borders
  '@box-shadow-base': '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle box-shadow
  '@background-color-base': '#f0f0f0', // Light background
  '@btn-primary-bg': '#ff4da6', // Primary button background
  '@btn-primary-color': '#ffffff', // Primary button text color
  '@btn-default-bg': '#f5f5f5', // Default button background
  '@btn-default-color': '#333333', // Default button text color
  '@btn-border-radius': '8px', // Button border radius
};

export const darkTheme = {
  '@primary-color': '#ff4da6', // Electric Pink for core accents
  '@link-color': '#70f3ff', // Turquoise Cyan for links
  '@success-color': '#4caf50', // Same as before for success
  '@warning-color': '#ff9800', // Same as before for warnings
  '@error-color': '#f5222d', // Same as before for errors
  '@font-family': '"Poppins", "Roboto", sans-serif',
  '@heading-color': '#4d9fff', // Neon Blue for headings
  '@text-color': '#f5f5f5', // Off-White for main text
  '@text-color-secondary': '#bfbfbf', // Lighter gray for secondary text
  '@border-radius-base': '10px',
  '@border-color-base': '#444', // Darker borders for a clean separation
  '@box-shadow-base': '0 4px 12px rgba(0, 0, 0, 0.2)', // Stronger shadows for dark mode
  '@background-color-base': '#1a1a2e', // Midnight Navy for the background
  '@background-color-light': '#c28fff', // Lavender Purple for light sections or highlights
  '@btn-primary-bg': '#ff4da6', // Primary button background
  '@btn-primary-color': '#ffffff', // Primary button text color
  '@btn-default-bg': '#2d2d2d', // Default button background (dark)
  '@btn-default-color': '#f5f5f5', // Default button text color (light)
  '@btn-border-radius': '8px', // Button border radius
};
