import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Typography, Button, Modal, message, Spin } from 'antd';
import axios from 'axios';
import { useBookingContext } from '../../context/BookingContext';
import './PartnerDisplay.css';

const { Title, Paragraph, Text } = Typography;

interface Partner {
  _id: string;
  providerId: string;
  companyName: string;
  contactPerson: string;
  email: string;
  location: string;
  essentialsPrice?: number; // Will be dynamically fetched based on service
  logoUrl: string;
}

interface PricingResponse {
  services: {
    key: string;
    essentials: { price: number };
  }[];
}

interface AvailabilityResponse {
  availability: {
    day: string;
    times: { startTime: string; endTime: string }[];
  }[];
}

interface ReservedTime {
  userId: string;
  date: string;
  time: string;
  paymentStatus: string;
}

interface PartnerDisplayProps {
  selectedService: string;
  onProviderSelect: (partner: Partner) => void;
}

// Helper function to convert service names to camelCase
const toCamelCase = (str: string): string => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase()
    )
    .replace(/\s+/g, '');
};

const PartnerDisplay: React.FC<PartnerDisplayProps> = ({ selectedService, onProviderSelect }) => {
  const [partners, setPartners] = useState<Partner[]>([]);
  const [availablePartners, setAvailablePartners] = useState<Partner[]>([]);
  const [unavailablePartners, setUnavailablePartners] = useState<Partner[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<Partner | null>(null);
  const [isUnavailableModalVisible, setIsUnavailableModalVisible] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const { bookingDetails } = useBookingContext();

  // Fetch partner pricing
  const fetchPartnerPricing = async (providerId: string, serviceKey: string) => {
    try {
      const response = await axios.get<PricingResponse>(`${process.env.REACT_APP_BACKEND_URI}/api/pricing/provider-pricing/${providerId}`);
      const service = response.data.services.find((s) => s.key === serviceKey);
      return service?.essentials.price || 'N/A'; // Return essentials price
    } catch (error) {
      console.error('Error fetching partner pricing:', error);
      return 'N/A';
    }
  };

  // Fetch partners based on the selected service
  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const serviceKey = toCamelCase(selectedService);
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/api/certifiedPartner/certified-partners/service/${serviceKey}`);
        const partnersWithPricing = await Promise.all(
          response.data.map(async (partner: Partner) => {
            const essentialsPrice = await fetchPartnerPricing(partner.providerId, serviceKey);
            return { ...partner, essentialsPrice };
          })
        );
        setPartners(partnersWithPricing);
        setLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        console.error('Error fetching certified partners:', error);
        message.error('Error fetching partners.');
        setLoading(false); // Set loading to false in case of error
      }
    };

    if (selectedService) {
      setLoading(true); // Set loading to true when fetching data
      fetchPartners();
    }
  }, [selectedService]);

  const checkAvailability = useCallback(async () => {
    if (!bookingDetails.date || !bookingDetails.time || !bookingDetails.dayOfWeek) {
      return;
    }

    const available: Partner[] = [];
    const unavailable: Partner[] = [];

    for (const partner of partners) {
      try {
        const providerId = partner.providerId;
        const bookingDate = new Date(bookingDetails.date).toISOString().split('T')[0]; // Format date as 'YYYY-MM-DD'
        const bookingTime = new Date(bookingDetails.time).toLocaleTimeString('en-US', { hour12: false }); // Time in 'HH:MM' format
        const bookingDayOfWeek = bookingDetails.dayOfWeek; // Day of the week (e.g., 'Monday')

        // 1. Check Reserved Times
        const reservedTimesResponse = await axios.get<{ reservedTimes: ReservedTime[] }>(
          `${process.env.REACT_APP_BACKEND_URI}/api/reserved-times/reserved-times/${providerId}`
        );

        const isTimeReserved = reservedTimesResponse.data.reservedTimes.some(
          (reservation) => reservation.date === bookingDate && reservation.time === bookingTime
        );

        if (isTimeReserved) {
          unavailable.push(partner);
          continue; // Skip to the next partner
        }

        // 2. Check Provider Availability
        const availabilityResponse = await axios.get<AvailabilityResponse>(
          `${process.env.REACT_APP_BACKEND_URI}/api/provider-availability/availability/${providerId}`
        );

        const dayAvailability = availabilityResponse.data.availability.find(
          (day) => day.day === bookingDayOfWeek
        );

        if (!dayAvailability) {
          unavailable.push(partner);
          continue;
        }

        // Check if the booking time falls within any of the available time ranges
        const isTimeAvailable = dayAvailability.times.some((timeRange) => {
          return bookingTime >= timeRange.startTime && bookingTime <= timeRange.endTime;
        });

        if (isTimeAvailable) {
          available.push(partner);
        } else {
          unavailable.push(partner);
        }
      } catch (error) {
        console.error('Error checking partner availability:', error);
        unavailable.push(partner);
      }
    }

    setAvailablePartners(available);
    setUnavailablePartners(unavailable);
  }, [partners, bookingDetails.date, bookingDetails.time, bookingDetails.dayOfWeek]);

  useEffect(() => {
    if (partners.length > 0) {
      checkAvailability();
    }
  }, [partners, bookingDetails.date, bookingDetails.time, bookingDetails.dayOfWeek, checkAvailability]);

  const handleSelectPartner = (partner: Partner) => {
    setSelectedPartner(partner);
    onProviderSelect(partner);
  };

  const showUnavailablePartners = () => {
    setIsUnavailableModalVisible(true);
  };

  const handleModalClose = () => {
    setIsUnavailableModalVisible(false);
  };

  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
      {loading ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Spin size="large" />
        </div>
      ) : partners.length === 0 ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Text>No certified partners available for this service.</Text>
        </div>
      ) : (
        <Row gutter={[16, 16]}>
          {availablePartners.map((partner) => (
            <Col key={partner._id} xs={24} sm={12} md={8} lg={6}>
              <Card
                hoverable
                className={selectedPartner && selectedPartner._id === partner._id ? 'selected-card' : ''}
                onClick={() => handleSelectPartner(partner)}
              >
                <img src={partner.logoUrl} alt={partner.companyName} style={{ width: '100%' }} />
                <div>
                  <Title level={3}>{partner.companyName}</Title>
                  <Paragraph>Contact: {partner.contactPerson}</Paragraph>
                  <Paragraph>Email: {partner.email}</Paragraph>
                  <Paragraph>Location: {partner.location}</Paragraph>
                  <Text strong>Starting at Price:</Text>
                  <Paragraph>${partner.essentialsPrice || 'N/A'}</Paragraph>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      )}

      {unavailablePartners.length > 0 && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button onClick={showUnavailablePartners}>Show Unavailable Partners</Button>
        </div>
      )}

      <Modal visible={isUnavailableModalVisible} onCancel={handleModalClose} footer={null}>
        <Title level={4}>Unavailable Partners</Title>
        <Row gutter={[16, 16]}>
          {unavailablePartners.map((partner) => (
            <Col key={partner._id} xs={24} sm={12} md={8} lg={6}>
              <Card hoverable>
                <img src={partner.logoUrl || 'placeholder_image_url'} alt={partner.companyName} style={{ width: '100%' }} />
                <div>
                  <Title level={4}>{partner.companyName}</Title>
                  <Paragraph>Contact: {partner.contactPerson}</Paragraph>
                  <Paragraph>Email: {partner.email}</Paragraph>
                  <Paragraph>Location: {partner.location}</Paragraph>
                  <Text strong>Starting at Price:</Text>
                  <Paragraph>${partner.essentialsPrice || 'N/A'}</Paragraph>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Modal>
    </section>
  );
};

export default PartnerDisplay;
